import { FormControl, Stack } from "@mui/material";
import {
  OrderOut,
  OrderIn,
  PatientIn,
  useWebApiV1UpdateOrderMutation,
  useWebApiV1UpdatePatientMutation,
  PatientOut,
  useProtocolApiV1ListDataQuery,
} from "@providers/hop-ord-server/api";
import { useCallback, useEffect, useMemo } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
// @ts-expect-error types for the package are not up to date
import { DatePickerElement } from "react-hook-form-mui/date-pickers";
import {
  FormContainer,
  RadioButtonGroup,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { debounce } from "lodash";
import { toServerDateString } from "@utils";
import dayjs, { Dayjs } from "dayjs";
import { PLAN_DELIVERY_OPTIONS } from "./constants";
import { ListDataCategory } from "@enums";

export interface InputTypes {
  patient: {
    firstName: string | null;
    lastName: string | null;
    dateOfBirth?: Dayjs;
    medicalReferenceNumber: string | null;
  };
  order: {
    planDelivery: string;
    treatingDepartment: string;
  };
}

interface Props {
  patientId: number;
  orderId: number;
  orderData: OrderOut;
  patientData?: PatientOut;
  isReadOnly: boolean;
  setForm?: (form: UseFormReturn<InputTypes> | null) => void;
}

const PatientDetailsForm = ({
  patientId,
  orderId,
  orderData,
  patientData,
  isReadOnly,
  setForm,
}: Props) => {
  const [updatePatient] = useWebApiV1UpdatePatientMutation();
  const [updateOrder] = useWebApiV1UpdateOrderMutation();
  const { data: treatingDepartments } = useProtocolApiV1ListDataQuery({
    categories: [ListDataCategory.TREATING_DEPARTMENT],
  });

  const form = useForm<InputTypes>({
    defaultValues: {
      order: {
        planDelivery: orderData.planDelivery ?? "standard",
        treatingDepartment: orderData.treatingDepartment || undefined,
      },
      patient: {
        ...patientData,
        dateOfBirth: patientData?.dateOfBirth
          ? dayjs(patientData?.dateOfBirth)
          : undefined,
      },
    },
    mode: "all",
  });

  useEffect(() => {
    if (!setForm) return;
    setForm(form);
  }, [setForm, form]);

  // create some debounced updaters
  // react isn't happy if you provide the debounce fn as the callback
  // so we momoise the result of `debounce` and use it for callbacks later
  const debouncedUpdatePatient = useMemo(
    () =>
      debounce((v) => {
        updatePatient({
          patientId,
          patientIn: { ...v, dateOfBirth: v.dateOfBirth || null },
        }).then();
      }, 1000),
    [patientId, updatePatient],
  );

  const debouncedUpdateOrder = useMemo(
    () =>
      debounce((v) => {
        updateOrder({
          orderId,
          orderIn: v,
        }).then();
      }, 1000),
    [orderId, updateOrder],
  );

  // create callbacks using the memoised change handlers
  const handlePatientChange = useCallback(
    (v: PatientIn) => debouncedUpdatePatient(v),
    [debouncedUpdatePatient],
  );

  const handleOrderChange = useCallback(
    (v: OrderIn) => debouncedUpdateOrder(v),
    [debouncedUpdateOrder],
  );

  const watch = form.watch;

  // setup the watch, making sure to unsub it once the cmpt dismounts
  useEffect(() => {
    const { unsubscribe } = watch((v, { name }) => {
      const groupName = name?.split(".")[0];
      switch (groupName) {
        case "patient":
          {
            const dateOfBirth = toServerDateString(
              v.patient?.dateOfBirth as Dayjs,
            );
            handlePatientChange({
              ...v.patient,
              dateOfBirth,
            });
          }
          break;
        case "order":
          handleOrderChange(v.order ?? {});
          break;
      }
    });
    return () => unsubscribe();
  }, [handlePatientChange, handleOrderChange, watch]);

  return (
    <FormContainer formContext={form}>
      <Stack direction="column" spacing={2}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextFieldElement
            fullWidth
            label="First Name"
            name="patient.firstName"
            required
            disabled={isReadOnly}
            data-testid="form-first-name"
          />
          <TextFieldElement
            fullWidth
            label="Last Name"
            name="patient.lastName"
            required
            disabled={isReadOnly}
            data-testid="form-last-name"
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <DatePickerElement
            required
            name="patient.dateOfBirth"
            label="Date of birth"
            desktopModeMediaQuery="(min-width:600px)"
            inputProps={{
              onBlur: () => form.trigger("patient.dateOfBirth"),
              fullWidth: true,
              name: "patient.dateOfBirth",
            }}
            disabled={isReadOnly}
            data-testid="form-dob"
          />
          <TextFieldElement
            fullWidth
            label="Medical Reference Number (MRN)"
            name="patient.medicalReferenceNumber"
            disabled={isReadOnly}
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <FormControl fullWidth>
            <RadioButtonGroup
              row
              label="Plan Delivery"
              name="order.planDelivery"
              required
              options={PLAN_DELIVERY_OPTIONS}
              disabled={isReadOnly}
            />
          </FormControl>
          <SelectElement
            required
            label="Treating Location/Unit"
            name="order.treatingDepartment"
            options={treatingDepartments?.map((td) => ({
              id: td.value,
              label: td.value,
            }))}
            fullWidth
            disabled={isReadOnly}
            data-testid="form-department"
          />
        </Stack>
      </Stack>
    </FormContainer>
  );
};

export default PatientDetailsForm;
