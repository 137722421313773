import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { Middleware } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

class RTKError extends Error {
  constructor(message: string) {
    super();
    this.name = "RTK Query Error";
    this.message = message;
  }
}

export const rtkQueryErrorLogger: Middleware =
  () => (next) => (action: any) => {
    if (isRejectedWithValue(action)) {
      const queryName = action.meta.arg.endpointName;
      const statusCode = action.payload.status;
      const errorMessage = action.payload.data?.message || "No error message";
      const error = new RTKError(`${queryName}: ${statusCode} ${errorMessage}`);

      Sentry.withScope((scope) => {
        scope.setExtra("action", action);
        scope.setExtra("queryArgs", action.meta.arg.originalArgs);
        Sentry.captureException(error);
      });
    }
    return next(action);
  };
