import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { theme } from "@theme";
import { DosePrescription } from "@components/SiteTable/SiteTable.types";
import {
  getDoses,
  getDoseFractions,
  doseDisplayValue,
} from "@utils/doseAndFractions";

interface Props {
  rows: DosePrescription[];
  doseUnit: string;
  fractionUnit: string;
}

const PdfOrderTable = ({ rows, doseUnit, fractionUnit }: Props) => {
  return (
    <TableContainer sx={{ backgroundColor: theme.palette.background.default }}>
      <Table size="small">
        <TableHead>
          <TableRow
            sx={{ th: { fontWeight: theme.typography.fontWeightBold } }}
          >
            <TableCell width="10%">Treatment Site</TableCell>
            <TableCell align="left" width="10%">
              Laterality
            </TableCell>
            <TableCell align="left" width="10%">
              Technique
            </TableCell>
            <TableCell align="left" width="10%">
              Energy
            </TableCell>
            <TableCell align="left" width="10%">
              {`Dose (${doseUnit})`}
            </TableCell>
            <TableCell align="left" width="10%">
              {`Fraction (${fractionUnit})`}
            </TableCell>
            <TableCell align="left" width="10%">
              {`Dose (${doseUnit}/${fractionUnit})`}
            </TableCell>
            <TableCell align="left" width="10%">
              Prescription PT/Isodose
            </TableCell>
            <TableCell align="left" width="10%">
              Sequence
            </TableCell>
            <TableCell align="left" width="10%">
              Frequency
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row: DosePrescription, index: number) => {
            const doses = getDoses(row);
            const doseFractions = getDoseFractions(row.fractions ?? 1, doses);

            return (
              <TableRow key={index} data-testid={`site-row-${index}`}>
                <TableCell data-testid="treatment-site">{`${row?.name} ${
                  row?.nameSpecify ?? ""
                }`}</TableCell>
                <TableCell data-testid="laterality">
                  {row?.laterality}
                </TableCell>
                <TableCell data-testid="technique">{row?.technique}</TableCell>
                <TableCell data-testid="energy">{row?.energy}</TableCell>
                <TableCell data-testid="doses">
                  {doseDisplayValue(doses)}
                </TableCell>
                <TableCell data-testid="fractions">{row?.fractions}</TableCell>
                <TableCell data-testid="dose-fractions">
                  {doseDisplayValue(doseFractions)}
                </TableCell>
                <TableCell data-testid="isodose">{row?.isodose}</TableCell>
                <TableCell data-testid="sequence">{row?.sequence}</TableCell>
                <TableCell data-testid="frequency">{row?.frequency}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PdfOrderTable;
