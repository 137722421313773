import { UploadedFile } from "@components/OrderTrackingFileList/Interface";
import {
  parseJsonDataFromServer,
  readJsonFile,
} from "@components/OrderTrackingFileList/utils";
import scorecardDataValidation from "@components/ScorecardDataValidation/ScorecardDataValidation";
import {
  WalkthroughAttachmentUpload,
  useWebApiV1CreateAttachmentMutation,
  useWebApiV1CreateWalkthroughAttachmentUploadsMutation,
  useWebApiV1DeleteAttachmentMutation,
  useWebApiV1DeleteWalkthroughAttachmentMutation,
  useWebApiV1ListAttachmentsQuery,
  useWebApiV1ListWalkthroughAttachmentsQuery,
  useWebApiV1UpdateWalkthroughAttachmentMutation,
} from "@providers/hop-ord-server/api";
import { useCallback, useEffect, useState } from "react";
import { FileRejection } from "react-dropzone";
import {
  UPLOAD_ERRORS,
  FILE_DATA_INVALID,
} from "@components/Dropzone/constants";
import { uploadToS3 } from "@utils/s3";
const MOVIE_FILE_TYPES = [".mov", ".mp4"];

export const useTrackingFiles = (orderId: number) => {
  const [trackingMode, setTrackingMode] = useState<
    "edit" | "submitted" | "unknown"
  >("unknown");
  const [invalidFiles, setInvalidFiles] = useState<UploadedFile[]>([]);
  const [scorecardFiles, setScorecardFiles] = useState<UploadedFile[]>([]);
  const [planWalkthroughFiles, setPlanWalkthroughFiles] = useState<
    UploadedFile[]
  >([]);
  const [createWalkthroughMutation] =
    useWebApiV1CreateWalkthroughAttachmentUploadsMutation();
  const [deleteWalkthroughMutation] =
    useWebApiV1DeleteWalkthroughAttachmentMutation();
  const [deleteScorecardMutation] = useWebApiV1DeleteAttachmentMutation();
  const [submitScorecardMutation] = useWebApiV1CreateAttachmentMutation();
  const [walkthroughMutation] =
    useWebApiV1UpdateWalkthroughAttachmentMutation();
  const { data: uploadedFileData } = useWebApiV1ListAttachmentsQuery({
    orderId,
  });
  const { data: uploadedWalkthroughFileData } =
    useWebApiV1ListWalkthroughAttachmentsQuery({
      orderId,
    });

  // Set scorecard files from server into state
  useEffect(() => {
    uploadedFileData &&
      parseJsonDataFromServer(uploadedFileData).then(
        (attachments: UploadedFile[]) => {
          setScorecardFiles((prevFiles) => [
            ...attachments, // only update the scorecard files from server, leave the non submitted ones.
            ...prevFiles.filter((file) => file.id === -1),
          ]);
        },
      );
  }, [uploadedFileData]);

  // Set plan walkthrough files from server into state
  useEffect(() => {
    uploadedWalkthroughFileData &&
      setPlanWalkthroughFiles((prevFiles) => {
        // only select the files currently sent for review and added in this session
        const currentFilesIds = prevFiles.map((file) => file.id);
        const deletedFileIds = prevFiles
          .filter((file) => file.isDeleted)
          .map((file) => file.id);
        return [
          ...uploadedWalkthroughFileData
            .filter(
              (file) => currentFilesIds.includes(file.id) || file.sentForReview,
            )
            .map((file): UploadedFile => {
              return {
                id: file.id,
                baseFile: new File([], file.fileName),
                fileUrl: file.file,
                fileSize: file.fileSize,
                fileStatus: file.isValid
                  ? file.sentForReview
                    ? "Sent"
                    : "Uploaded"
                  : "uploading",
                lastModifiedDate: new Date(file.lastModifiedDate),
                createdAt: new Date(file.createdAt),
                uploadErrors: "",
                isDeleted: deletedFileIds.includes(file.id),
                planLockedTimestamp: "",
              };
            }),
        ];
      });
  }, [uploadedWalkthroughFileData]);

  // Set tracking mode to submitted if there are files from server and no invalid files
  useEffect(() => {
    if (
      trackingMode === "unknown" &&
      uploadedWalkthroughFileData &&
      uploadedFileData
    ) {
      uploadedWalkthroughFileData.filter((file) => file.sentForReview)
        .length !== 0 || uploadedFileData.scorecards.length !== 0
        ? setTrackingMode("submitted")
        : setTrackingMode("edit");
    }
  }, [uploadedWalkthroughFileData, uploadedFileData, trackingMode]);

  const handleDropFiles = useCallback(
    async (acceptedFiles: File[], rejections: FileRejection[]) => {
      const newApprovedFiles: UploadedFile[] = [];
      const newRejectedFiles: UploadedFile[] = [];

      await Promise.all(
        // Process scorecard files
        acceptedFiles
          .filter(
            (file) =>
              !MOVIE_FILE_TYPES.some((type) => file.name.endsWith(type)),
          )
          .map(async (item) => {
            const jsonData = await readJsonFile(item);
            const isValid = scorecardDataValidation(jsonData);
            if (isValid) {
              newApprovedFiles.push({
                id: -1,
                baseFile: item,
                fileSize: item.size,
                fileStatus: "Uploaded",
                lastModifiedDate: new Date(item.lastModified),
                createdAt: new Date(),
                uploadErrors: "",
                isDeleted: false,
                uploadProgress: 100,
                planLockedTimestamp:
                  jsonData?.PlanApproval?.Timestamp || "Not recorded",
              });
            } else {
              newRejectedFiles.push({
                id: -1,
                baseFile: item,
                lastModifiedDate: new Date(item.lastModified),
                createdAt: new Date(),
                uploadErrors: UPLOAD_ERRORS[FILE_DATA_INVALID],
                isDeleted: false,
                planLockedTimestamp: "",
              });
            }
          }),
      );
      // Process walkthrough files
      const walkthroughFiles = acceptedFiles.filter((file) =>
        MOVIE_FILE_TYPES.some((type) => file.name.endsWith(type)),
      );
      // get presigned urls for the file uploads
      createWalkthroughMutation({
        orderId: orderId,
        fileNames: walkthroughFiles.map((file) => file.name),
      })
        .unwrap()
        .then((onfullfilled) => {
          const newWalkthroughFiles = onfullfilled.map(
            (item: WalkthroughAttachmentUpload): UploadedFile => {
              return {
                id: item.id,
                baseFile: walkthroughFiles.find(
                  (file) => file.name === item.fileName,
                ) as File,
                fileUrl: "",
                fileSize: 0,
                fileStatus: "uploading",
                lastModifiedDate: new Date(),
                createdAt: new Date(),
                uploadErrors: "",
                isDeleted: false,
                uploadPayload: item.uploadUrl,
                uploadProgress: 0,
                planLockedTimestamp: "",
              };
            },
          );
          setPlanWalkthroughFiles((prevFiles) => [
            ...prevFiles,
            ...newWalkthroughFiles,
          ]);
          newWalkthroughFiles.forEach(async (file) => {
            // upload the file to s3 and update the progress
            file.uploadPayload &&
              uploadToS3(
                file.uploadPayload,
                file.baseFile,
                (progress) => {
                  // Update the progress
                  setPlanWalkthroughFiles((prevFiles) =>
                    prevFiles.map((prevFile) => {
                      if (prevFile.id === file.id) {
                        return {
                          ...prevFile,
                          uploadProgress: progress,
                        };
                      }
                      return prevFile;
                    }),
                  );
                },
                () => {
                  // on complete update the file status
                  setPlanWalkthroughFiles((prevFiles) =>
                    prevFiles.map((prevFile) => {
                      if (prevFile.id === file.id) {
                        return {
                          ...prevFile,
                          uploadProgress: 100,
                          fileStatus: "Uploaded",
                        };
                      }
                      return prevFile;
                    }),
                  );
                  // submit that the file is uploaded
                  walkthroughMutation({
                    fileId: file.id,
                    orderId: orderId,
                    uploadComplete: true,
                  });
                },
                () => {
                  // on error, remove the file from the walkthrough files and add them to the invalid files
                  setPlanWalkthroughFiles((prevFiles) => [
                    ...prevFiles.filter(
                      (prevFile): boolean => prevFile.id !== file.id,
                    ),
                  ]);
                  setInvalidFiles((prevFiles) => [
                    ...prevFiles,
                    {
                      ...file,
                      uploadErrors: "error uploading file",
                    },
                  ]);
                },
              );
          });
        });
      // Process invalid files
      rejections.forEach((item) =>
        newRejectedFiles.push({
          id: -1,
          baseFile: item.file,
          lastModifiedDate: new Date(),
          createdAt: new Date(),
          uploadErrors:
            UPLOAD_ERRORS[item.errors[0].code as keyof typeof UPLOAD_ERRORS],
          isDeleted: false,
          planLockedTimestamp: "",
        }),
      );
      setScorecardFiles((prevFiles) => [...prevFiles, ...newApprovedFiles]);
      setInvalidFiles((prev) => [...newRejectedFiles, ...prev]);
    },
    [orderId, createWalkthroughMutation, walkthroughMutation],
  );

  const clearInvalidFiles = useCallback(() => {
    // clear the invalid files and delete the files from the server
    setInvalidFiles((prevFiles) => {
      prevFiles.forEach((file) => {
        if (file.id !== -1) {
          // delete the file from the server
          deleteWalkthroughMutation({
            orderId: orderId,
            attachmentId: file.id,
          });
        }
      });
      return [];
    });
  }, [deleteWalkthroughMutation, orderId]);

  const deleteInvalidFile = useCallback(
    (file: UploadedFile) => {
      // delete the file from the state
      setInvalidFiles((prevFiles) => [
        ...prevFiles.filter((prevFile) => {
          if (prevFile === file && file.id !== -1) {
            // delete the file from the server
            deleteWalkthroughMutation({
              orderId: orderId,
              attachmentId: file.id,
            });
          }
          return file !== prevFile;
        }),
      ]);
    },
    [deleteWalkthroughMutation, orderId],
  );

  const deleteScorecardFile = useCallback(
    (file: UploadedFile, index: number) => {
      // Mark the scorecard file as deleted in the list if the file is from the server
      // else remove from the list
      if (file.id !== -1) {
        setScorecardFiles((prevFiles) => [
          ...prevFiles.map((prevFile) => {
            if (file.id === prevFile.id) {
              return {
                ...prevFile,
                isDeleted: true,
              };
            }
            return prevFile;
          }),
        ]);
        return;
      }
      // delete the file from the state
      setScorecardFiles((prevFiles) => [
        ...prevFiles.filter((_, fileIndex) => index !== fileIndex),
      ]);
    },
    [],
  );

  const deleteWalkthroughFile = useCallback(
    (file: UploadedFile) => {
      // delete the file from the state
      setPlanWalkthroughFiles((prevFiles) => [
        ...prevFiles
          .map((prevFile) =>
            file === prevFile ? { ...file, isDeleted: true } : prevFile,
          )
          .filter((prevFile) => {
            if (prevFile.id !== file.id || file.fileStatus === "Sent")
              return true;
            // delete the file from the server and remove from list
            deleteWalkthroughMutation({
              orderId: orderId,
              attachmentId: file.id,
            });
            return false;
          }),
      ]);
    },
    [deleteWalkthroughMutation, orderId],
  );

  // discard file changes
  const discardChanges = useCallback(() => {
    // clear the invalid files
    clearInvalidFiles();

    // remove the temporary files from the scorecard files
    setScorecardFiles((prevFiles): UploadedFile[] => {
      if (prevFiles.some((file) => file.fileStatus === "Sent")) {
        setTrackingMode("submitted"); // if there are any files sent for review, set the tracking mode to submitted
      }
      return [
        ...prevFiles
          .filter((file) => file.fileStatus === "Sent")
          .map((file) => ({ ...file, isDeleted: false })),
      ];
    });
    // remove the files that were not send for review
    setPlanWalkthroughFiles((prevFiles) => {
      if (prevFiles.some((file) => file.fileStatus === "Sent")) {
        setTrackingMode("submitted"); // if there are any files sent for review, set the tracking mode to submitted
      }
      return [
        ...prevFiles.filter((file) => {
          if (file.id === -1) return false;
          if (file.fileStatus === "Sent") return true;
          deleteWalkthroughMutation({
            orderId: orderId,
            attachmentId: file.id,
          });
          return false;
        }),
      ].map((file) => ({ ...file, isDeleted: false }));
    });
  }, [clearInvalidFiles, deleteWalkthroughMutation, orderId]);

  //submit scorecard file to the server
  const submitScorecard = useCallback(
    async (file: UploadedFile) => {
      if (!orderId || !file) return;

      const scorecard = await readJsonFile(file?.baseFile);

      submitScorecardMutation({
        orderId,
        scorecardAttachmentIn: {
          fileName: file.baseFile.name,
          scorecard,
        },
      });
    },
    [orderId, submitScorecardMutation],
  );

  const sendForReview = useCallback(() => {
    // send the files to the server or delete files from the server
    scorecardFiles.forEach((file) => {
      if (file.fileStatus === "Uploaded") {
        submitScorecard(file);
      }
      if (file.isDeleted) {
        deleteScorecardMutation({ orderId: orderId, attachmentId: file.id });
      }
    });
    planWalkthroughFiles.forEach((file) => {
      if (file.fileStatus === "Uploaded") {
        // send the file for review
        walkthroughMutation({
          orderId,
          fileId: file.id,
          sentForReview: true,
        });
      }
      if (file.isDeleted) {
        deleteWalkthroughMutation({ orderId: orderId, attachmentId: file.id });
      }
    });

    // remove the invalid files
    clearInvalidFiles();
    setScorecardFiles((prevFiles) => [
      ...prevFiles.filter((file) => file.id !== -1),
    ]);
    // mark the tracking mode to be submitted
    setTrackingMode("submitted");
  }, [
    deleteScorecardMutation,
    deleteWalkthroughMutation,
    orderId,
    planWalkthroughFiles,
    scorecardFiles,
    submitScorecard,
    walkthroughMutation,
    clearInvalidFiles,
  ]);

  return {
    trackingMode,
    invalidFiles,
    scorecardFiles,
    planWalkthroughFiles,
    setTrackingMode,
    handleDropFiles,
    clearInvalidFiles,
    deleteInvalidFile,
    deleteScorecardFile,
    deleteWalkthroughFile,
    discardChanges,
    sendForReview,
  };
};
