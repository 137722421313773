import { OrderStatus } from "@providers/hop-ord-server/api";
import { ArrowDropDown } from "@mui/icons-material";
import { Button, ButtonGroup, Menu, MenuItem } from "@mui/material";
import { useRef, useState } from "react";
import { orderStatusMapping, orderStatusColourMapping } from "@enums";

interface StatusItemProps {
  status: OrderStatus;
  setStatus: (status: OrderStatus) => void;
  selected: boolean;
}

const StatusItem = ({ status, setStatus, selected }: StatusItemProps) => {
  return (
    <MenuItem
      value={status}
      onClick={() => setStatus(status)}
      selected={selected}
      data-testid={`menu-item-${status}`}
    >
      {orderStatusMapping[status]}
    </MenuItem>
  );
};

interface Props {
  status: OrderStatus;
  statuses: OrderStatus[];
  setStatus: (status: OrderStatus) => void;
}

const StatusButtonGroup = ({ status, statuses, setStatus }: Props) => {
  const anchorRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const setStatusAndClose = (status: OrderStatus) => {
    setStatus(status);
    setMenuOpen(false);
  };
  const color = orderStatusColourMapping[status];
  // ButtonGroup and Button do not support the `default` colour out of the box
  // so we have to manually set the colours
  return (
    <>
      <ButtonGroup
        variant="contained"
        size="small"
        sx={{
          color: `${color}.main`,
          "& .MuiButtonGroup-grouped": {
            borderColor: `${color}.dark`,
          },
        }}
      >
        <Button
          data-testid="status-indicator"
          sx={{
            color: `${color}.contrastText`,
            bgcolor: `${color}.main`,
            ":hover": { bgcolor: `${color}.dark` },
          }}
        >
          {orderStatusMapping[status]}
        </Button>
        <Button
          size="small"
          ref={anchorRef}
          onClick={() => setMenuOpen(true)}
          data-testid="open-menu-btn"
          sx={{
            color: `${color}.contrastText`,
            bgcolor: `${color}.main`,
            ":hover": { bgcolor: `${color}.dark` },
          }}
          aria-controls={menuOpen ? "split-button-menu" : undefined}
          aria-expanded={menuOpen ? "true" : undefined}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={anchorRef.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        data-testid="status-menu"
      >
        {statuses.map((thisStatus) => (
          <StatusItem
            key={thisStatus}
            status={thisStatus}
            setStatus={setStatusAndClose}
            selected={thisStatus === status}
          />
        ))}
      </Menu>
    </>
  );
};

export default StatusButtonGroup;
