import { useEffect, useState } from "react";
import { ScorecardInfo, UploadedFile } from "./Interface";
import { Box, Stack, useTheme } from "@mui/system";
import dayjs from "dayjs";
import { IconButton, Modal, Slide, Typography } from "@mui/material";
import { toLocaleDateTimeString } from "@utils";
import { Close as CloseIcon } from "@mui/icons-material";
import Scorecard from "@components/Scorecard";

export const ScorecardPreviewModal = ({
  file,
  onClose,
}: {
  file: UploadedFile;
  onClose: () => void;
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewFile, setPreviewData] = useState<ScorecardInfo | null>(null);
  const theme = useTheme();

  const openPreview = async (file: UploadedFile) => {
    setPreviewData({
      fileName: file.baseFile.name,
      size: file.baseFile.size,
      uploadDate: dayjs(file.createdAt),
      planLockedTimestamp: file.planLockedTimestamp,
      scorecardData: JSON.parse(await file.baseFile.text()),
    });
    setPreviewOpen(true);
  };

  useEffect(() => {
    openPreview(file);
  }, [file]);

  return (
    <Modal open={previewOpen} onClose={() => setPreviewOpen(false)}>
      <Slide in={previewOpen} direction="up">
        <Stack padding={2} height={1} bgcolor={theme.palette.grey[900]}>
          <Stack direction="row" color="white" justifyContent="space-between">
            <Stack>
              <Typography>{previewFile?.fileName}</Typography>
              <Typography>
                {`${
                  previewFile && Math.round(previewFile.size / 1024)
                }KB • Plan locked: ${
                  previewFile?.planLockedTimestamp || "Not recorded"
                } • Uploaded: ${
                  previewFile?.uploadDate &&
                  toLocaleDateTimeString(previewFile?.uploadDate.toString())
                }`}
              </Typography>
            </Stack>
            <IconButton
              onClick={() => {
                setPreviewOpen(false);
                onClose();
              }}
              color="inherit"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            overflow="auto"
            justifyContent="center"
            alignItems="center"
            height={1}
          >
            <Box overflow="auto" width={{ xs: "80%", lg: "50%" }}>
              {previewFile && <Scorecard data={previewFile.scorecardData} />}
            </Box>
          </Stack>
        </Stack>
      </Slide>
    </Modal>
  );
};
