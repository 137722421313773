import Ajv, { JSONSchemaType } from "ajv";
import { ScorecardObject } from "@providers/hop-ord-server/api";

const jsonSchema: JSONSchemaType<ScorecardObject> = {
  title: "Dosimetric Criterion Results",
  description: "Schema for json scorecard uploaded by dosimetrists",
  type: "object",
  properties: {
    DVHStatistics: {
      type: "array",
      items: {
        type: "object",
        properties: {
          Structure: { type: "string" },
          IsTarget: { type: "boolean", nullable: true },
          DosimetricCriteria: {
            type: "array",
            items: {
              type: "object",
              properties: {
                Criterion: { type: "string" },
                Outcome: { type: "string" },
                Actual: { type: "string" },
              },
              required: ["Criterion", "Outcome", "Actual"],
            },
          },
        },
        required: ["Structure", "DosimetricCriteria"],
      },
    },
    PlanApproval: {
      type: "object",
      properties: {
        Timestamp: { type: "string", nullable: true },
      },
    },
  },
  required: ["DVHStatistics"],
};

const scorecardDataValidation = (jsonData: ScorecardObject) => {
  const ajv = new Ajv();
  const validate = ajv.compile(jsonSchema);
  const isValid = validate(jsonData);
  return isValid;
};

export default scorecardDataValidation;
