import { OrderStatus, ListDataCategory } from "@enums";
import {
  Box,
  Button,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { parseListData } from "@utils";
import {
  WebApiV1CloneOrderApiResponse,
  useWebApiV1CloneOrderMutation,
  useWebApiV1ListSiteGroupsQuery,
  useWebApiV1OrderQuery,
  useWebApiV1UpdateOrderMutation,
  useProtocolApiV1ListDataQuery,
  useNotificationsApiV1UpdateNotificationsMutation,
} from "@providers/hop-ord-server/api";
import {
  useWebApiV1ListWalkthroughAttachmentsQuery,
  useWebApiV1ReadAttachmentMutation,
  useWebApiV1UpdateWalkthroughAttachmentMutation,
} from "@providers/hop-ord-server/api";
import { useWebApiV1ListAttachmentsQuery } from "@providers/hop-ord-server/api";
import { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ActionBar, OrderHeader } from "@components";
import OrderTrackerDrawer, {
  PanelType,
  DEFAULT_DRAWER,
} from "./components/OrderTrackerDrawer/OrderTrackerDrawer";
import {
  ActionIcons,
  ScorecardDisplayPanel,
  WalkthroughDisplayPanel,
} from "./components";
import AcceptOrderModal from "./components/AcceptOrderModal/AcceptOrderModal";
import RejectOrderModal from "./components/RejectOrderModal/RejectOrderModal";

const OrderTrackerPage = () => {
  const { orderId } = useParams();
  const { data: orderData } = useWebApiV1OrderQuery({
    orderId: Number(orderId),
  });
  const { data: siteGroupData } = useWebApiV1ListSiteGroupsQuery({
    orderId: Number(orderId),
  });
  const categories = [ListDataCategory.REJECT_REASON];
  const { data: listData } = useProtocolApiV1ListDataQuery({
    categories,
  });
  const { data: uploadedFileData } = useWebApiV1ListAttachmentsQuery({
    orderId: Number(orderId),
  });

  const { data: walkthroughData } = useWebApiV1ListWalkthroughAttachmentsQuery({
    orderId: Number(orderId),
  });

  const [cloneOrder] = useWebApiV1CloneOrderMutation();
  const [updateOrder] = useWebApiV1UpdateOrderMutation();
  const [markScorecardAsRead] = useWebApiV1ReadAttachmentMutation();
  const [updateWalkthrough] = useWebApiV1UpdateWalkthroughAttachmentMutation();
  const [updateNotifications] =
    useNotificationsApiV1UpdateNotificationsMutation();

  const [selectedDrawerPanel, setSelectedDrawerPanel] =
    useState<PanelType>(DEFAULT_DRAWER);
  const [approveModalIsOpen, setAcceptModalIsOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<"scorecard" | "walkthrough">(
    "scorecard",
  );
  const navigate = useNavigate();

  const handleEditOrder = () => {
    if (!orderData?.id) {
      return;
    }
    cloneOrder({ orderId: orderData.id }).then((res) => {
      const payload = (
        res as unknown as { data: WebApiV1CloneOrderApiResponse }
      ).data;
      navigate(`/order/${payload}`);
    });
  };

  const listOptions = useMemo(
    () => parseListData(listData || [], categories),
    [listData],
  );

  // if an RO is looking at this page, consider the unread scorecards as read
  useEffect(() => {
    for (const sc of uploadedFileData?.scorecards.filter((v) => !v.read) ??
      []) {
      markScorecardAsRead({
        attachmentId: sc.id,
        orderId: Number(orderId),
      });
    }
  }, [markScorecardAsRead, orderId, uploadedFileData?.scorecards]);

  useEffect(() => {
    for (const walkthrough of walkthroughData?.filter((v) => !v.read) ?? []) {
      updateWalkthrough({
        fileId: walkthrough.id,
        read: true,
        orderId: Number(orderId),
      });
    }
  }, [updateWalkthrough, orderId, walkthroughData]);

  // Set the order status notifications as viewed.
  useEffect(() => {
    if (updateNotifications && orderData) {
      updateNotifications({
        orderGroupId: orderData.orderGroupId,
        notificationIn: { notificationType: "ORDER_STATUS" },
      });
    }
  }, [updateNotifications, orderData]);

  const showAttachments =
    orderData?.status !== OrderStatus.SUBMITTED &&
    orderData?.status !== OrderStatus.IN_PROGRESS;

  return (
    <>
      <div>
        <OrderHeader
          title="Plan Tracking"
          orderData={orderData}
          right={
            <ActionIcons
              drawerOpen={drawerOpen}
              orderData={orderData}
              setDrawerOpen={setDrawerOpen}
              setSelectedPanel={setSelectedDrawerPanel}
              handleEditOrder={handleEditOrder}
              isRo={true}
            />
          }
        >
          {orderData?.status === OrderStatus.READY_FOR_RO_APPROVAL ? (
            <ActionBar
              text="Review Plan"
              isLoading={!orderData}
              actions={
                <Stack direction="row" spacing={1}>
                  <Button
                    data-testid="tracking-reject-btn"
                    variant="outlined"
                    color="error"
                    onClick={() => setRejectModalOpen(true)}
                  >
                    reject
                  </Button>
                  <Button
                    data-testid="tracking-approve-btn"
                    variant="contained"
                    color="success"
                    onClick={() => setAcceptModalIsOpen(true)}
                  >
                    approve
                  </Button>
                </Stack>
              }
            />
          ) : !orderData ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              data-testid="loading-action-bar"
            >
              <Skeleton width={300} variant="text" />
              <Stack direction="row" gap={1}>
                <Skeleton variant="rounded" width={100} height={36} />
                <Skeleton variant="rounded" width={100} height={36} />
              </Stack>
            </Stack>
          ) : (
            <Typography>
              Thank you for your order. We'll notify you once the plan is ready
              for review.
            </Typography>
          )}
        </OrderHeader>

        {showAttachments && (
          <Stack overflow="auto" height={1} width={1}>
            <Box paddingTop={3}>
              <Tabs
                value={selectedTab}
                onChange={(_event, value) => {
                  setSelectedTab(value);
                }}
                aria-label="tracker tabs"
              >
                <Tab label="Scorecards" value="scorecard" />
                <Tab label="Plan walkthroughs" value="walkthrough" />
              </Tabs>

              {selectedTab === "scorecard" ? (
                <ScorecardDisplayPanel
                  isLoading={!uploadedFileData}
                  scorecards={uploadedFileData?.scorecards || []}
                />
              ) : (
                <WalkthroughDisplayPanel walkthroughs={walkthroughData} />
              )}
            </Box>
          </Stack>
        )}
      </div>

      <OrderTrackerDrawer
        open={drawerOpen}
        setOpen={setDrawerOpen}
        selectedPanel={selectedDrawerPanel}
        setSelectedPanel={setSelectedDrawerPanel}
        orderData={orderData}
        siteGroupData={siteGroupData || []}
      />

      <RejectOrderModal
        open={rejectModalOpen}
        setOpen={setRejectModalOpen}
        order={orderData}
        rejectReasonOptions={listOptions[ListDataCategory.REJECT_REASON]}
      />

      <AcceptOrderModal
        open={approveModalIsOpen}
        setOpen={setAcceptModalIsOpen}
        updateOrder={() => {
          updateOrder({
            orderId: Number(orderId),
            orderIn: { status: OrderStatus.APPROVED },
          });
          setAcceptModalIsOpen(false);
        }}
      />
    </>
  );
};

export default OrderTrackerPage;
