import { CssBaseline, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import Layout from "@pages/Layout/layout";
import { useNavigate } from "react-router-dom";

const LogoutOnInactivityPage = () => {
  const navigate = useNavigate();
  return (
    <CssBaseline>
      <Layout user={{}}>
        <Box
          sx={{ p: 2 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="90vh"
        >
          <Typography variant="body1" align="center">
            <div data-testid="logout-inactivity-text">
              You have been logged out due to inactivity
            </div>
            <Button variant="contained" onClick={() => navigate("/")}>
              Login
            </Button>
          </Typography>
        </Box>
      </Layout>
    </CssBaseline>
  );
};

export default LogoutOnInactivityPage;
