import { Portal } from "@mui/material";
import { ReactNode } from "react";
import { Drawer } from "@mui/material";
import { theme } from "@theme";

interface Props {
  children: ReactNode;
  permanent: boolean;
  setOpen: (open: boolean) => void;
  open: boolean;
  drawerWidth: number;
}

const PortaledRightDrawer = ({
  permanent,
  open,
  setOpen,
  children,
  drawerWidth,
}: Props) => {
  const topOffset = document.getElementById("app-bar")?.clientHeight;
  const container = document.getElementById("layout-right-bar");
  return (
    <Portal container={container}>
      <Drawer
        variant={permanent ? "permanent" : "temporary"}
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          ".MuiDrawer-paper": {
            boxSizing: "content-box",
            width: drawerWidth,
            zIndex: theme.zIndex.appBar - 1,
            top: topOffset,
            height: `calc(100vh - ${topOffset}px)`,
            border: "none",
          },
        }}
      >
        {children}
      </Drawer>
    </Portal>
  );
};

export default PortaledRightDrawer;
