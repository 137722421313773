import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import {
  OrderOut,
  useWebApiV1RejectOrderMutation,
} from "@providers/hop-ord-server/api";
import { useCommentsApiV1PostCommentMutation } from "@providers/hop-ord-server/api";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  AutocompleteElement,
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";

const OTHER = "Other";
interface ReasonOption {
  id: string;
  label: string;
}
export interface InputTypes {
  rejectReason: ReasonOption[];
  additionalInfo: string;
}

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  order?: OrderOut;
  rejectReasonOptions: string[];
}

const RejectOrderModal = ({
  open,
  setOpen,
  order,
  rejectReasonOptions,
}: Props) => {
  const [rejectOrder] = useWebApiV1RejectOrderMutation();
  const [addNewComment] = useCommentsApiV1PostCommentMutation();

  const form = useForm<InputTypes>({
    defaultValues: {
      rejectReason: [],
      additionalInfo: "",
    },
    mode: "all",
  });

  const submitPayload: SubmitHandler<InputTypes> = () => {
    // Check everything is valid
    const payload = form.getValues();
    rejectOrder({
      orderId: Number(order?.id),
      rejectOrderIn: {
        rejectReason: payload.rejectReason.map((reason) => reason.id),
        rejectedAdditionalInfo: payload.additionalInfo,
      },
    }).then(() => {
      if (!order?.orderGroupId) return;
      const content = `Rejected order because of the following reason(s): ${payload.rejectReason
        .map((reason) => reason.id)
        .join(", ")}${payload.additionalInfo ? "," : ""} ${
        payload.additionalInfo
      }`;
      addNewComment({
        orderGroupId: order?.orderGroupId,
        commentIn: {
          content,
          orderId: Number(order?.id),
        },
      });
    });
    setOpen(false);
  };

  const isAdditionalInfoMandatory = form
    .watch("rejectReason")
    .map((reason) => reason.id)
    .includes(OTHER);

  return (
    <Dialog open={open}>
      <FormContainer
        formContext={form}
        handleSubmit={form.handleSubmit(submitPayload)}
      >
        <DialogTitle data-testid="dialog-title">Reject order</DialogTitle>
        <DialogContent data-testid="dialog-content">
          <Stack spacing={2}>
            <Typography
              variant="body1"
              data-testid="dialog-content-description"
            >
              Please provide reasons for rejecting so our dosimetrist team can
              make the necessary amendments.
            </Typography>
            <AutocompleteElement
              label="Reject reasons"
              multiple={true}
              options={rejectReasonOptions.map((option) => ({
                id: option,
                label: option,
              }))}
              name="rejectReason"
              required={true}
              showCheckbox={true}
            />
            <TextFieldElement
              label="Additional information"
              name="additionalInfo"
              multiline
              rows={4}
              data-testid="dialog-content-additional-info"
              helperText="Please indicate the applicable plans if there are multiple"
              rules={{
                required: {
                  value: isAdditionalInfoMandatory,
                  message: "Please specify other rejection reason",
                },
              }}
            />
            <Typography variant="body1" data-testid="dialog-content-note">
              Note: if a change to the order is required (e.g. prescription,
              target objectives) please also edit the order to ensure the
              scorecards are accurate.
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setOpen(false);
            }}
            data-testid="reject-modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            data-testid="reject-modal-reject-btn"
            variant="text"
            color="error"
            type="submit"
          >
            Reject
          </Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
};

export default RejectOrderModal;
