import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface DeleteOrderModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  deleteOrder: () => void;
}

const ProtocolFilters = ({
  open,
  setOpen,
  deleteOrder,
}: DeleteOrderModalProps) => {
  const confirm = async () => {
    deleteOrder();
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Discard order?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to discard this order? <br />
          This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button variant="text" color="error" onClick={confirm}>
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProtocolFilters;
