import { Tooltip, Typography, TypographyProps } from "@mui/material";
import { useRef, useEffect, useState, ReactNode } from "react";

interface TextOverflowTooltipProps extends TypographyProps {
  children: ReactNode;
}

const TextOverflowTooltip = ({
  children,
  ...restProps
}: TextOverflowTooltipProps) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  useEffect(() => {
    const compareSize = () => {
      if (ref.current) {
        const compare = ref.current.scrollWidth > ref.current.clientWidth;
        setTooltipEnabled(compare);
      }
    };
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => window.removeEventListener("resize", compareSize);
  }, [children]);

  const typographyProps: TypographyProps = {
    ref: ref,
    noWrap: true,
    ...restProps,
  };

  return tooltipEnabled ? (
    <Tooltip title={children}>
      <Typography {...typographyProps}>{children}</Typography>
    </Tooltip>
  ) : (
    <Typography {...typographyProps}>{children}</Typography>
  );
};

export default TextOverflowTooltip;
