import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alertOpen: false,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    openAlert(state) {
      state.alertOpen = true;
    },
  },
});

export const { openAlert } = settingsSlice.actions;
export default settingsSlice.reducer;
