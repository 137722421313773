import dayjs, { Dayjs } from "dayjs";
import React from "react";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import checkFeatureActive from "./featureSwitch";

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export enum SEPARATOR_TYPE {
  COMMA = "comma",
  DOT = "dot",
}

// separator = comma: 7-Sept-2023, 1:26 PM
// separator = dot: 1:26PM • 7-Sept-2023
export const toLocaleDateTimeString = (
  datetime: Date | string | undefined | Dayjs,
  mode: "datetime" | "date" | "time" = "datetime",
  separator: SEPARATOR_TYPE.COMMA | SEPARATOR_TYPE.DOT = SEPARATOR_TYPE.COMMA,
): string => {
  if (datetime === undefined || datetime === "") {
    throw new Error("Blank or undefined date");
  }

  datetime = dayjs(datetime).toDate();
  const parts = new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hourCycle: "h12",
  })
    .formatToParts(new Date(datetime))
    .map((p) => p.value);

  const date = `${parts[0]}-${parts[2]}-${parts[4]}`;
  const time = `${parts[6]}:${parts[8]} ${parts[10].toUpperCase()}`;

  switch (mode) {
    case "datetime":
      return separator === SEPARATOR_TYPE.DOT
        ? `${time} • ${date}`
        : `${date}, ${time}`;
    case "date":
      return date;
    case "time":
      return time;
  }
};

export const getTimeZoneShort = (date: Date): string => {
  // https://github.com/iamkun/dayjs/issues/1154
  // dayjs has bug with .format(z) where it returns GMT + 10 instead of AEST
  return (
    dayjs(date)
      .format("zzz")
      .match(/\b(\w)/g)
      ?.join("") || ""
  );
};

export const dateOfBirthFormat = (dob: string): string => {
  return dayjs(dob).format("DD MMM YYYY");
};

export const toServerDateString = (
  date?: Date | string | Dayjs,
): string | null => {
  const datestr = dayjs(date).format("YYYY-MM-DD");
  if (datestr === "Invalid Date") {
    return "";
  }
  return datestr;
};

export interface ListDataOptionType {
  value: string;
  category: string;
}

export const mappedListData = (
  listData: ListDataOptionType[] | undefined,
  category: string,
) => {
  if (listData) {
    return listData
      .filter((option: ListDataOptionType) => option.category === category)
      .map((option: ListDataOptionType) => option.value);
  }
  return [];
};

export const isNumeric = (num: unknown) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num as number);

export const areDoseValuesValid = (
  highDose: number | string | null,
  mid1Dose: number | string | null,
  mid2Dose: number | string | null,
  lowDose: number | string | null,
) => {
  const doses = [highDose, mid1Dose, mid2Dose, lowDose].filter(
    (dose) => dose !== null,
  );
  // high dose should always be set
  if (
    highDose === null ||
    !isNumeric(highDose) ||
    parseFloat(highDose.toString()) <= 0
  )
    return false;
  const nonNullDoses: (string | number)[] = doses.filter(
    (dose) => dose !== null,
  ) as (string | number)[];
  // if the doses are not numeric then return false
  if (
    nonNullDoses.some(
      (dose) =>
        !isNumeric(dose) || dose === "" || parseFloat(dose.toString()) <= 0,
    )
  )
    return false;
  // if the doses are not in ascending order then return false
  if (
    nonNullDoses.some(
      (dose, index) =>
        index > 0 &&
        parseFloat(dose.toString()) >=
          parseFloat(nonNullDoses[index - 1].toString()),
    )
  )
    return false;
  return true;
};

export const areDosesInOrder = (doses: (string | number)[]) => {
  const numberDoses = doses.map((dose) => parseFloat(dose.toString()));
  return numberDoses.map(
    (dose, index) =>
      index === 0 ||
      !numberDoses.some((dose2, index2) => index2 < index && dose >= dose2),
  );
};

export const isDoseArrayValid = (doses: (string | number | null)[]) => {
  type doseDictType = {
    highDose: number | string | null;
    mid1Dose: number | string | null;
    mid2Dose: number | string | null;
    lowDose: number | string | null;
  };
  const doseDict: doseDictType = {
    highDose: null,
    mid1Dose: null,
    mid2Dose: null,
    lowDose: null,
  };
  const doseMapping = [
    [],
    ["highDose"],
    ["highDose", "lowDose"],
    ["highDose", "mid1Dose", "lowDose"],
    ["highDose", "mid1Dose", "mid2Dose", "lowDose"],
  ];
  if (!Array.isArray(doses)) return false;
  doseMapping[doses.length].forEach((dose, index) => {
    doseDict[dose as keyof doseDictType] = doses[index];
  });
  return areDoseValuesValid(
    doseDict.highDose,
    doseDict.mid1Dose,
    doseDict.mid2Dose,
    doseDict.lowDose,
  );
};

export const useInterval = (callback: () => void, delay: number | null) => {
  const intervalRef = React.useRef<number | null>(null);
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  React.useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalRef.current || 0);
    }
  }, [delay]);
  return intervalRef;
};

export const isSpecifyMandatory = (site_name: string | null) =>
  site_name?.toLocaleLowerCase().includes("specify") || false;

export type ListDataOptionSerialised = {
  value: string;
  category: string;
};

export const parseListData = (
  optionData: ListDataOptionSerialised[],
  categories: string[],
): { [category: string]: string[] } => {
  const options = Object.fromEntries(
    categories.map((c) => [c, [] as string[]]),
  );
  if (optionData === undefined) {
    return options;
  }
  for (const ldo of optionData) {
    options[ldo.category]?.push(ldo.value);
  }
  return options;
};

export const timeFromNow = (
  createdAt: string,
  mode: "datetime" | "date" = "datetime",
) => {
  // If time is today, show relative time (e.g. 3 mins ago).
  // Otherwise show exact time and date (e.g. 3:02PM • 27-May-2023)
  if (!createdAt) return "";

  const NOW_THRESHOLD_SECS = 6;
  const today = new Date();
  const timesince = today.getTime() - new Date(createdAt).getTime();
  const secondsSince = Math.floor(timesince / 1000);
  const minutesSince = Math.floor(secondsSince / 60);
  const hoursSince = Math.floor(minutesSince / 60);

  function pluralise(val: number, suffix: string): string {
    const plural = val > 1 ? `${val} ${suffix}s` : `${val} ${suffix}`;
    return `${plural} ago`;
  }

  if (
    toLocaleDateTimeString(createdAt, "date") !==
    toLocaleDateTimeString(today, "date")
  ) {
    return toLocaleDateTimeString(
      createdAt,
      mode === "date" ? "date" : "datetime",
    );
  }

  if (hoursSince > 0) {
    return pluralise(hoursSince, "hr");
  }
  if (minutesSince > 0) {
    return pluralise(minutesSince, "min");
  }
  if (secondsSince > NOW_THRESHOLD_SECS) {
    return pluralise(secondsSince, "sec");
  }
  return "Now";
};

export const getAvatarText = (name: string | undefined): string | undefined =>
  name &&
  name
    .toUpperCase()
    .split(" ")
    .map((n) => n[0])
    .join("")
    .slice(-2);

export { checkFeatureActive };
