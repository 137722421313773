import React from "react";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const Auth0ProviderWithNavigate = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const domain = process.env.VITE_AUTH0_DOMAIN;
  const clientId = process.env.VITE_AUTH0_CLIENT_ID;
  const redirectUri = process.env.VITE_AUTH0_REDIRECT_URI;
  const audience = process.env.VITE_AUTH0_AUDIENCE;

  const onRedirectCallback = (appState: AppState | undefined): void => {
    navigate(appState?.returnTo || window.location.pathname);
  };
  if (!(domain && clientId && redirectUri)) {
    return <div>Missing Auth0 config</div>;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: audience,
        scope: "read:patient openid profile email",
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      useRefreshTokensFallback // TODO: this fixes an error (Uncaught (in promise) Error: Missing Refresh Token) that only happends on firefox
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
