import {
  Box,
  IconButton,
  CustomTheme,
  useTheme,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import ImageModal from "@components/Comments/CommentsComponents/ImageModal";

interface ImageBoxProps {
  testId?: string;
  fileName: string;
  url: string;
  onDelete?: () => void;
}

const ImageBox = (props: ImageBoxProps): JSX.Element => {
  const theme = useTheme<CustomTheme>();
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Box
        position="relative"
        bgcolor="rgba(0, 0, 0, 0.04)"
        height={240}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {/* Filename Overlay */}
        <Box
          bgcolor="rgba(0, 0, 0, 0.50)"
          width="100%"
          position="absolute"
          top={0}
          left={0}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1}
          zIndex={5}
          color={theme.palette.common.white}
        >
          <Typography noWrap style={{ flexGrow: 1 }}>
            {props.fileName}
          </Typography>

          {props.onDelete && (
            <IconButton color="inherit" onClick={props.onDelete}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>

        {/* Image */}
        <img
          src={props.url}
          alt="Uploaded"
          onClick={() => setModalOpen(true)}
          data-testid={props.testId}
          style={{
            cursor: "pointer",
            maxHeight: "100%",
            maxWidth: "100%",
            position: "relative",
          }}
        />
      </Box>
      <ImageModal
        url={props.url}
        fileName={props.fileName}
        isOpen={modalOpen}
        handleClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};
export default ImageBox;
