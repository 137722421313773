import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import { DataTable, DataTableRefObject } from "@components/DataTable/DataTable";
import { Typography, Stack } from "@mui/material";
import { GridValidRowModel } from "@mui/x-data-grid";
import { TargetObjectiveOut } from "@providers/hop-ord-server/api";
import { CustomCellTextField } from "@components/DataTable/CustomCellTextField";
import { CustomCellBoldDisplay } from "@components/DataTable/CustomCellBoldDisplay";
import React, { Ref, useImperativeHandle } from "react";
import { theme } from "@theme";

export interface TargetObjectiveInfo extends TargetObjectiveOut {
  errors?: string[];
  touched?: string[];
}
interface TargetObjectiveTableProps {
  data: TargetObjectiveInfo[];
  updateRow: (targetObjective: GridValidRowModel) => boolean;
  isReadOnly: boolean;
  doseLength: number;
  dataTestId: string;
}

export interface TargetObjectDataTableRefObject {
  trigger: () => boolean;
}

const doseToDisplayValue = (dose: string, doseLength: number): string => {
  if (doseLength <= 1) return "";
  if (dose == "low") return "Low";
  if (dose == "mid_one") return doseLength == 4 ? "Mid-1" : "Mid";
  if (dose == "mid_two") return "Mid-2";
  return "High";
};

const TargetObjectiveTable = React.forwardRef(
  (
    props: TargetObjectiveTableProps,
    ref: Ref<TargetObjectDataTableRefObject>
  ) => {
    const tableRef = React.useRef<DataTableRefObject>(null);
    const { doseLength, isReadOnly, updateRow, data, dataTestId } = props;
    const isEditable = !isReadOnly;

    const columns: GridColDef[] = [
      {
        field: "doseType",
        headerName: "",
        editable: false,
        flex: 0,
        sortable: false,
        type: "string",
        valueGetter: (_value, row) => {
          return doseToDisplayValue(row.doseType, doseLength);
        },
        renderCell: (params: GridRenderEditCellParams) => (
          <CustomCellBoldDisplay
            dataTestId={`dose-type-${params.row.doseType}`}
            {...params}
            readOnly={!isEditable}
          />
        ),
      },
      {
        field: "gtv",
        headerName: "GTV",
        editable: isEditable,
        flex: 1,
        sortable: false,
        type: "string",
        renderCell: (params: GridRenderEditCellParams) => (
          <CustomCellTextField
            dataTestId={`gtv-${params.row.doseType}-view`}
            multiline={true}
            {...params}
            readOnly={!isEditable}
          />
        ),
        renderEditCell: (params: GridRenderEditCellParams) => (
          <CustomCellTextField
            dataTestId={`gtv-${params.row.doseType}-edit`}
            multiline={true}
            {...params}
            readOnly={false}
          />
        ),
      },
      {
        field: "ctv",
        headerName: "CTV",
        editable: isEditable,
        flex: 1,
        sortable: false,
        type: "string",
        renderCell: (params: GridRenderEditCellParams) => (
          <CustomCellTextField
            dataTestId={`ctv-${params.row.doseType}-view`}
            multiline={true}
            {...params}
            readOnly={!isEditable}
          />
        ),
        renderEditCell: (params: GridRenderEditCellParams) => (
          <CustomCellTextField
            dataTestId={`ctv-${params.row.doseType}-edit`}
            multiline={true}
            {...params}
            readOnly={false}
          />
        ),
      },
      {
        field: "ptv",
        headerName: "PTV",
        editable: isEditable,
        flex: 1,
        sortable: false,
        type: "string",
        renderCell: (params: GridRenderEditCellParams) => (
          <CustomCellTextField
            dataTestId={`ptv-${params.row.doseType}-view`}
            multiline={true}
            {...params}
            readOnly={!isEditable}
          />
        ),
        renderEditCell: (params: GridRenderEditCellParams) => (
          <CustomCellTextField
            dataTestId={`ptv-${params.row.doseType}-edit`}
            multiline={true}
            {...params}
            readOnly={false}
          />
        ),
      },
    ];

    useImperativeHandle(ref, () => ({
      trigger: (): boolean => {
        return tableRef.current?.trigger() || false;
      },
    }));

    return (
      <Stack
        sx={{
          padding: "16px 24px 24px 24px",
          backgroundColor: theme.palette.grey[50],
        }}
        data-testid={dataTestId}
      >
        <Typography variant="body1" pb={2}>
          Target objectives
        </Typography>
        <Stack bgcolor="white">
          <DataTable
            columns={columns}
            rows={data || []}
            updateRow={updateRow}
            emptyRowComment="Please add a dose"
            ref={tableRef}
          />
        </Stack>
      </Stack>
    );
  }
);

export default TargetObjectiveTable;
