interface DosesProps {
  highDose?: number | null | undefined;
  mid1Dose?: number | null | undefined;
  mid2Dose?: number | null | undefined;
  lowDose?: number | null | undefined;
}

export const getDoses = ({
  highDose,
  mid1Dose,
  mid2Dose,
  lowDose,
}: DosesProps): (number | null)[] => {
  const doses = [highDose, mid1Dose, mid2Dose, lowDose].filter(
    (dose): dose is number => dose !== null && dose !== undefined
  );
  return doses;
};

export const getDoseFractions = (
  fractions: number,
  doses: (number | null)[]
): (number | null)[] => {
  const doseFractions = doses.map(
    (doseValue) =>
      fractions &&
      doseValue &&
      Math.round((doseValue / fractions + Number.EPSILON) * 100) / 100
  );
  return doseFractions;
};

export const doseLabels = [
  [""],
  [""],
  ["High", "Low"],
  ["High", "Mid", "Low"],
  ["High", "Mid-1", "Mid-2", "Low"],
];

export const doseDisplayValue = (
  doses: (number | null | undefined)[] | null
) => {
  if (!Array.isArray(doses)) return [""];
  return doses.length !== 1
    ? doses
        .map((item, index) => `${doseLabels[doses.length][index]}: ${item}`)
        .join("\n")
    : doses[0];
};
