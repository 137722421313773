import { useEffect } from "react";
import { unstable_usePrompt } from "react-router-dom";

export const ExitPrompt = ({
  when,
  message,
}: {
  when: boolean;
  message: string;
}) => {
  unstable_usePrompt({ when, message });
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    if (when) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [when, message]);
  return null;
};
