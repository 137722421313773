import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { setCredentials } from "./features/auth/auth0Slice";
import { useEffect } from "react";
import {
  RODashboard,
  OrderPage,
  ErrorPage,
  FulfillmentPage,
  SentryErrorPage,
  RoOrderTrackerPage,
  DosiOrderTrackerPage,
} from "@pages";
import DosimetristDashboard from "./pages/Dashboard/DosimetristDashboard";
import { sec } from "./app/security";
import { Route, Routes } from "react-router-dom";
import { Role, Feature } from "./enums";
import { Navigate } from "react-router-dom";
import Layout from "./pages/Layout/layout";
import { CssBaseline, Stack } from "@mui/material";
import * as Sentry from "@sentry/react";
import LogoutOnInactivity from "@pages/LogoutOnInactivity/component/LogoutOnInactivity";
import MuiXLicense from "@utils/MuiXLicense";
import { ErrorAlert } from "@components";
import CommandCenter from "@pages/CommandCenter/CommandCenter";
import { useWebApiV1GetUserFeatureSwitchesQuery } from "@providers/hop-ord-server/api";

const INACTIVITY_TIMEOUT = parseInt(
  process.env.VITE_INACTIVITY_TIMEOUT_MINUTES || "60",
);

const userHomeRedirect = (roles: string[], accessToken: boolean): string => {
  // Return home page url for user based on role (to be redirected post login)
  // Multi roles will redirect to /order for now
  const { data: featureSwitchData, isLoading } =
    useWebApiV1GetUserFeatureSwitchesQuery(undefined, { skip: !accessToken });

  if (!accessToken || isLoading) {
    return "";
  }

  let redirectTo = "*";
  if (roles.includes(Role.DOSIMETRIST)) {
    if (
      featureSwitchData?.find(
        (feature) => feature.name === Feature.AUTOMATION_INTEGRATION_FEATURE,
      )
    ) {
      redirectTo = "/commandcenter";
    } else {
      redirectTo = "/fulfillment";
    }
  }
  if (roles.includes(Role.RO)) {
    redirectTo = "/order";
  }
  return redirectTo;
};

const App = () => {
  const {
    user,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const updateAccessToken = async () => {
      const token = await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      sec.setAccessTokenSilently(getAccessTokenSilently);
      // Send only user sub which is unique to user in Auth0 and not PII to Sentry
      const sentry_name = `${user?.sub} | ${user?.name}`;
      Sentry.setUser({ username: sentry_name });

      const credentials = {
        user: user?.name || "",
        token: token,
        idToken: idToken?.__raw || "",
        roles: user?.hop_roles?.filter((role: string) =>
          role.startsWith("role-"),
        ),
        tenants: user?.hop_roles?.filter((tenant: string) =>
          tenant.startsWith("tenant-"),
        ),
      };
      dispatch(setCredentials(credentials));
    };
    if (user) {
      updateAccessToken();
    }
  }, [user, dispatch, getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    if (!user && !isLoading) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
  }, [user, isLoading, loginWithRedirect]);

  const { roles, isAuthenticated: accessToken } = useAppSelector(
    (state) => state.auth,
  );

  const showSkeleton = !user || !accessToken;

  const redirectTo = userHomeRedirect(roles, accessToken);

  const alertOpen = useAppSelector((state) => state.settings.alertOpen);

  return (
    <CssBaseline>
      <Layout user={user} accessToken={accessToken}>
        <ErrorAlert open={alertOpen} />
        <MuiXLicense />
        {showSkeleton ? (
          <Stack data-testid="main-app-loading"></Stack>
        ) : (
          <>
            {/* Logout and redirect to inactivity page after 60 minutes of inactivity */}
            <LogoutOnInactivity idleMinutes={INACTIVITY_TIMEOUT} />
            <Routes>
              <Route path="/" element={<Navigate to={redirectTo} />} />
              <Route path="/commandcenter" element={<CommandCenter />} />
              <Route path="/order" element={<RODashboard />} />
              <Route path="/order/:orderId" element={<OrderPage />} />
              <Route
                path="/order/:orderId/tracker"
                element={<RoOrderTrackerPage />}
              />
              <Route path="/fulfillment" element={<DosimetristDashboard />} />
              <Route
                path="/fulfillment/:orderId"
                element={<FulfillmentPage />}
              />
              <Route
                path="/fulfillment/:orderId/tracker"
                element={<DosiOrderTrackerPage />}
              />
              <Route path="/sentry/error" element={<SentryErrorPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </>
        )}
      </Layout>
    </CssBaseline>
  );
};

export default App;
