import React, { Ref, useImperativeHandle } from "react";
import {
  DataGrid,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridRowModel,
  MuiEvent,
  useGridApiRef,
} from "@mui/x-data-grid";
import { DataTableProps } from "./Interface";
import { theme } from "@theme";

import { ClickAwayListener } from "@mui/material";
export interface DataTableRefObject {
  trigger: () => boolean;
}

export const DataTable = React.forwardRef(
  <T extends Partial<T>>(
    props: DataTableProps<T>,
    ref: Ref<DataTableRefObject>,
  ) => {
    const { rows, columns, updateRow } = props;
    const apiRef = useGridApiRef();
    const [cellModesModel, setCellModesModel] =
      React.useState<GridCellModesModel>({});

    useImperativeHandle(ref, () => ({
      trigger: (): boolean => {
        // check if there are any rows in edit mode
        return Object.keys(apiRef.current.state.editRows).length === 0;
      },
    }));
    // A generic handle for cell click. This method is called on each cell click which unsets the edit mode of all the cells/Rows
    // previously in edit mode and sets the edit mode of the clicked cell.
    const handleCellClick = React.useCallback(
      (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
        // Ignore if the cell is not editable
        if (!params.isEditable) {
          return;
        }

        // Ignore portal
        if (!event.currentTarget.contains(event.target as Element)) {
          return;
        }

        setCellModesModel((prevModel) => {
          return {
            // Revert the mode of the other cells from other rows
            ...Object.keys(prevModel).reduce(
              (acc, id) => ({
                ...acc,
                [id]: Object.keys(prevModel[id]).reduce(
                  (acc2, field) => ({
                    ...acc2,
                    [field]: { mode: GridCellModes.View },
                  }),
                  {},
                ),
              }),
              {},
            ),
            [params.id]: {
              // Revert the mode of other cells in the same row
              ...Object.keys(prevModel[params.id] || {}).reduce(
                (acc, field) => ({
                  ...acc,
                  [field]: { mode: GridCellModes.View },
                }),
                {},
              ),
              [params.field]: { mode: GridCellModes.Edit },
            },
          };
        });
      },
      [],
    );

    // updates the cellModesModel state when the cell mode changes
    const handleCellModesModelChange = React.useCallback(
      (newModel: GridCellModesModel) => {
        setCellModesModel(newModel);
      },
      [],
    );

    // updates the row when the row is updated
    const processRowUpdate = React.useCallback(
      (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        updateRow(newRow);
        return updatedRow;
      },
      [updateRow],
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
      console.error(error);
    }, []);

    return (
      <ClickAwayListener
        disableReactTree={true}
        onClickAway={(event) => {
          (event.target as HTMLBaseElement)?.tagName !== "LI" &&
            apiRef.current?.setRowSelectionModel([]);
        }}
      >
        <DataGrid
          apiRef={apiRef}
          autoHeight
          columns={columns}
          rows={rows}
          hideFooterPagination={true}
          hideFooter={true}
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          isCellEditable={() => !props.readonly}
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          onCellClick={handleCellClick}
          onCellDoubleClick={(_params, event) => {
            event.defaultMuiPrevented = true;
          }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          disableVirtualization={true}
          getRowHeight={() => "auto"}
          slots={{
            noRowsOverlay: () => (
              <div
                className="empty-datagrid-message"
                style={{
                  textAlign: "left",
                  padding: "16px 8px",
                  color: theme.palette.text.secondary,
                }}
              >
                {props.emptyRowComment || "No data"}
              </div>
            ),
          }}
          // Change this to use theme
          sx={{
            "& .MuiDataGrid-columnHeader": {
              padding: "1px",
            },
            "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
              padding: "0px",
            },
            "& .MuiDataGrid-cell": {
              padding: "0px",
            },
            "& .MuiDataGrid-columnHeaderDraggableContainer": {
              padding: "8px",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: 600,
            },
            "& .Mui-error": {
              border: "1px solid",
              borderColor: theme.palette.error.main,
              backgroundColor: theme.datagrid.cell.validationError,
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              borderBottom: `1px solid ${theme.palette.borderColor.light}`,
              "&:has(.empty-datagrid-message)": {
                height: "68px",
              },
            },
            "& .MuiDataGrid-overlayWrapperInner:has(.empty-datagrid-message)": {
              height: "68px!important",
              display: "flex",
              alignItems: "center",
            },
          }}
        />
      </ClickAwayListener>
    );
  },
);
