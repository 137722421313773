import {
  Alert,
  AlertTitle,
  Snackbar,
  Stack,
  Card,
  Autocomplete,
  Checkbox,
  TextField,
  Skeleton,
  Popper,
  InputAdornment,
  IconButton,
  MenuItem,
} from "@mui/material";
import {
  ProtocolInfoOut,
  useProtocolApiV1ListProtocolsQuery,
  useProtocolApiV1TreatmentSiteDataQuery,
  useWebApiV1CreateSiteGroupMutation,
} from "@providers/hop-ord-server/api";
import { useCallback, useEffect, useMemo, useState } from "react";
import ProtocolList from "./ProtocolList";
import SiteNameModal from "@pages/Order/components/SiteNameModal/SiteNameModal";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { protocolSearch, ProtocolResult } from "./protocolSearch";
import ClearIcon from "@mui/icons-material/Clear";

export interface ProtocolPanelProps {
  isReadOnly: boolean;
  orderId: number;
}

const ProtocolSearchFilterSkeleton = () => {
  return (
    <Stack direction={{ sm: "column", md: "row" }} gap={1}>
      <Skeleton
        variant="rounded"
        height="48px"
        sx={{
          width: { sm: "100%", md: "30%" },
        }}
      />
      <Skeleton
        variant="rounded"
        height="48px"
        sx={{
          width: { sm: "100%", md: "70%" },
        }}
      />
    </Stack>
  );
};

const ProtocolPanel = (props: ProtocolPanelProps) => {
  const [filteredProtocols, setFilteredProtocols] = useState<ProtocolInfoOut[]>(
    []
  );
  const [showAddSite, setShowAddSite] = useState<boolean>(false);
  const [filters, setFilters] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [hasExactMatches, setHasExactMatches] = useState(true);
  const [hasSimilarMatches, setHasSimilarMatches] = useState(true);
  const [addedAlertOpen, setAddedAlertOpen] = useState(false);
  const [addedProtocolName, setAddedProtocolName] = useState("");
  const [createSiteGroupMutation] = useWebApiV1CreateSiteGroupMutation();
  const [searchAndFilteredProtocols, setSearchAndFilteredProtocols] = useState<
    ProtocolResult[]
  >([]);

  const { data: protocolData, isLoading } = useProtocolApiV1ListProtocolsQuery({
    limit: 1000,
    offset: 0,
    orderBy: "popular",
  });

  /** Treatment site query */
  const { data: treatmentSiteData, isLoading: treatmentSiteLoading } =
    useProtocolApiV1TreatmentSiteDataQuery();

  const showSkeleton = treatmentSiteLoading || isLoading;

  const treatmentSiteOptions =
    treatmentSiteData?.map((site) => site.name) ?? [];

  const protocols = useMemo(() => {
    if (!protocolData) return [];
    return protocolData.items;
  }, [protocolData]);

  // Create Site Group Function
  const createSiteGroup = (
    protocolId?: number,
    name?: string,
    nameSpecify?: string
  ) => {
    if (props.isReadOnly) {
      return Promise.reject("read only");
    }
    return createSiteGroupMutation({
      orderId: Number(props.orderId),
      protocolId,
      manualSiteName: name,
      manualSiteSpecify: nameSpecify,
    });
  };

  const addProtocol = (
    protocol?: ProtocolInfoOut,
    name?: string,
    nameSpecify?: string
  ) => {
    if (props.isReadOnly) {
      return;
    }
    createSiteGroup(protocol?.id, name, nameSpecify).then(() => {
      setAddedProtocolName(protocol?.name ?? "Manual Site Group");
      setAddedAlertOpen(true);
    });
  };

  /* 
    Filter & Search:
    - First update the protocols list based on selected filters (filteredProtocols)
    - Second update the filteredProtocols list based on search term (searchAndFilteredProtocols)
    If no filters and no search term then return all results
  */
  const updateFilteredProtocols = useCallback(
    (selectedFilters: string[]) => {
      let filters = selectedFilters;

      // If no filters are selected, return all results
      if (!selectedFilters.length) {
        const tumourStreams = new Set(
          protocols.map((p: ProtocolInfoOut) => p.tumourStream)
        );
        filters = [...tumourStreams.keys()].sort();
      }
      setFilteredProtocols(
        protocols.filter((p) => filters.includes(p.tumourStream))
      );
    },
    [protocols]
  );

  useEffect(() => {
    const { hasExactMatches, hasSimilarMatches, results } = protocolSearch(
      searchText,
      filteredProtocols
    );
    setHasExactMatches(hasExactMatches);
    setHasSimilarMatches(hasSimilarMatches);
    setSearchAndFilteredProtocols(results);
  }, [filteredProtocols, searchText]);

  // get unique tumour streams
  useEffect(() => {
    const tumourStreams = new Set(
      protocols.map((p: ProtocolInfoOut) => p.tumourStream)
    );
    const newFilters = [...tumourStreams.keys()].sort();
    setFilters(newFilters);
    updateFilteredProtocols(newFilters);
  }, [protocols, updateFilteredProtocols]);

  return (
    <Stack>
      <Card sx={{ p: 2 }}>
        <Stack>
          {showSkeleton ? (
            <ProtocolSearchFilterSkeleton />
          ) : (
            <Stack
              direction={{ sm: "column", md: "row" }}
              alignItems="center"
              gap={2}
            >
              <TextField
                id="protocol-search"
                label="Search"
                variant="outlined"
                margin="normal"
                value={searchText}
                sx={{
                  width: { sm: "100%", md: "30%" },
                  marginTop: 0,
                  marginBottom: 0,
                }}
                InputLabelProps={{ shrink: true }}
                placeholder="Protocol name"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchText(event.target.value);
                }}
                InputProps={{
                  endAdornment: searchText && (
                    <InputAdornment position="end" sx={{ width: "30px" }}>
                      <IconButton
                        onClick={() => {
                          setSearchText("");
                        }}
                      >
                        {<ClearIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Autocomplete
                multiple
                data-testid="protocols-filter"
                options={filters}
                limitTags={2}
                disableCloseOnSelect
                disablePortal
                renderOption={(props, option, { selected }) => (
                  <MenuItem {...props} selected={selected}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      sx={{ marginRight: 1 }}
                      checked={selected}
                    />
                    {option}
                  </MenuItem>
                )}
                PopperComponent={({ style, ...props }) => (
                  <Popper {...props} style={{ ...style, zIndex: 99 }} />
                )}
                sx={{
                  width: { sm: "100%", md: "70%" },
                  "& + .MuiAutocomplete-popper .MuiPaper-root": {
                    maxHeight: "364px",
                  },
                }}
                ListboxProps={{
                  sx: {
                    maxHeight: "364px",
                  },
                }}
                onChange={(_, value) => {
                  updateFilteredProtocols([...value]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Tumor streams"
                  />
                )}
              />
            </Stack>
          )}

          <SiteNameModal
            open={showAddSite}
            options={treatmentSiteOptions}
            onSubmit={(name, nameSpecify) => {
              setShowAddSite(false);
              const manualProtocol = undefined;
              addProtocol(manualProtocol, name, nameSpecify);
            }}
            onDismiss={() => {
              setShowAddSite(false);
            }}
          />
        </Stack>
        <ProtocolList
          protocols={searchAndFilteredProtocols}
          isLoading={showSkeleton}
          isReadOnly={props.isReadOnly ?? false}
          addProtocol={addProtocol}
          setShowAddSite={setShowAddSite}
          hasExactMatches={hasExactMatches}
          hasSimilarMatches={hasSimilarMatches}
        />
        <Snackbar
          open={addedAlertOpen}
          onClose={() => setAddedAlertOpen(false)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert>
            <AlertTitle>Planning protocol added</AlertTitle>
            {addedProtocolName}
          </Alert>
        </Snackbar>
      </Card>
    </Stack>
  );
};

export default ProtocolPanel;
