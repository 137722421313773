interface Feature {
  id: number;
  name: string;
  tenant: string;
}

const checkFeatureActive = (
  features: Feature[],
  featureName: string,
  tenants: string[],
): boolean => {
  return features.some(
    (feature: Feature) =>
      feature.name === featureName && tenants.includes(feature.tenant),
  );
};

export default checkFeatureActive;
