import { OrderOut } from "@providers/hop-ord-server/api";
import { PanelType } from "../OrderTrackerDrawer/OrderTrackerDrawer";
import {
  Badge,
  Button,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { theme } from "@theme";
import {
  Article as ArticleIcon,
  Comment as CommentIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { OrderStatus } from "@enums";

interface OrderActionsProps {
  drawerOpen: boolean;
  setDrawerOpen: (isOpen: boolean) => void;
  setSelectedPanel?: (panel: PanelType) => void;
  orderData?: OrderOut;
  handleEditOrder?: () => void;
  isRo: boolean;
}

const ActionIcons = ({
  setDrawerOpen,
  drawerOpen,
  setSelectedPanel,
  orderData,
  handleEditOrder,
  isRo,
}: OrderActionsProps) => {
  const screenIsLarge = useMediaQuery(theme.breakpoints.up("lg"));

  if (!orderData) {
    return <Skeleton variant="circular" width={40} height={40} />;
  }

  return (
    <Stack direction="row" alignItems="baseline" spacing={2}>
      {!screenIsLarge && (
        <>
          <Tooltip title="View plan details">
            <IconButton
              onClick={() => {
                setSelectedPanel && setSelectedPanel("details");
                setDrawerOpen(!drawerOpen);
              }}
            >
              <ArticleIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Add to conversation">
            <IconButton
              onClick={() => {
                setSelectedPanel && setSelectedPanel("comments");
                setDrawerOpen(!drawerOpen);
              }}
            >
              <Badge badgeContent={0} color="secondary">
                <CommentIcon />
              </Badge>
            </IconButton>
          </Tooltip>
        </>
      )}
      {isRo &&
        [
          OrderStatus.SUBMITTED,
          OrderStatus.IN_PROGRESS,
          OrderStatus.READY_FOR_RO_APPROVAL,
          OrderStatus.REJECTED,
        ].includes(orderData.status) && (
          <Tooltip title="Edit order">
            {screenIsLarge ? (
              <Button
                startIcon={<EditIcon />}
                onClick={handleEditOrder}
                size="large"
                data-testid="edit-button"
              >
                Edit
              </Button>
            ) : (
              <IconButton onClick={handleEditOrder} data-testid="edit-button">
                <EditIcon />
              </IconButton>
            )}
          </Tooltip>
        )}
    </Stack>
  );
};

export default ActionIcons;
