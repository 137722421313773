import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Link,
} from "@mui/material";
import { supportEmail } from "../../constants";

const ErrorAlert = ({ open }: { open: boolean }) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          minWidth: 400,
        },
      }}
      maxWidth="xs"
      sx={{ letterSpacing: "0.15px" }}
    >
      <DialogTitle>Something went wrong</DialogTitle>
      <DialogContent>
        We have encountered an error. Please attempt to resolve by refreshing
        the page. If the issue persists, please contact Lumonus IT support at{" "}
        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
        <br />
        <br />
        Our team is working on this matter.
        <br />
        We appreciate your patience.
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => window.location.reload()}
          data-testid="refresh-button"
        >
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorAlert;
