import { CustomTheme, IconButton, TextField, useTheme } from "@mui/material";
import { MuiEvent, useGridApiContext } from "@mui/x-data-grid";
import { CustomTextEditCellParams } from "./Interface";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { isDoseArrayValid } from "@utils";
import { doseDisplayValue } from "@utils/doseAndFractions";

export const DoseViewField = (props: CustomTextEditCellParams) => {
  const apiRef = useGridApiContext();
  const theme = useTheme<CustomTheme>();
  const { id, value, field, readOnly } = props;
  const doses = value?.doses || [""];
  const [showAddIcon, setShowAddIcon] = useState(false);
  const isDoseValid = isDoseArrayValid(doses);
  const error =
    apiRef.current.getRow(id).touched?.includes(field) &&
    (apiRef.current.getRow(id).errors?.includes(field) || !isDoseValid);
  const multilineValue = doseDisplayValue(doses);
  return (
    <TextField
      data-testid="dose-view-field"
      error={error}
      variant="outlined"
      multiline={true}
      onMouseEnter={() => {
        !readOnly && doses && doses.length <= 1 && setShowAddIcon(true);
      }}
      onMouseLeave={() => {
        setShowAddIcon(false);
      }}
      sx={{
        height: "100%",
        "& .MuiInputBase-root": {
          padding: "0px 0px 0px 8px !important",
          fontSize: theme.datagrid.cell.fontSize,
          height: "100%",
          borderRadius: "0px",
        },
        "& fieldset": { border: "none" },
      }}
      onKeyDown={(event) => {
        event.stopPropagation();
      }}
      onClick={props.onClick}
      value={multilineValue}
      InputProps={{
        readOnly: readOnly,
        endAdornment: showAddIcon && (
          <IconButton
            data-testid="dose-view-field-add-icon"
            size="small"
            onClick={(event: MuiEvent) => {
              event.defaultMuiPrevented = true;
              const row = apiRef.current.getRow(id);
              apiRef.current.updateRows([{ ...row, openDialog: true }]);
              apiRef.current.startCellEditMode({ id, field });
            }}
            style={{ padding: "0px" }}
          >
            <AddIcon color="primary" />
          </IconButton>
        ),
      }}
    />
  );
};
