import {
  Stack,
  Typography,
  IconButton,
  Button,
  LinearProgress,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { toLocaleDateTimeString } from "@utils";
import { UploadedFile } from "./Interface";

const ONE_KILOBYTE = 1024;
const ONE_MEGABYTE = ONE_KILOBYTE * ONE_KILOBYTE;

const formatSize = (size: number): string => {
  if (size < ONE_KILOBYTE) return "<1 KB";
  if (size < ONE_MEGABYTE) return `${(size / ONE_KILOBYTE).toFixed(1)} KB`;
  return `${(size / ONE_MEGABYTE).toFixed(1)} MB`;
};

interface Props {
  file: UploadedFile;
  onDelete: (file: UploadedFile) => void;
  isValid: boolean;
  openPreview?: (file: UploadedFile) => void;
  fileIcon: React.ReactNode;
  isEditMode?: boolean;
}

const OrderTrackingFileInfo = ({
  file,
  onDelete,
  isValid = true,
  openPreview,
  fileIcon,
  isEditMode,
}: Props) => {
  const error = file?.uploadErrors;
  const fileStatus = file?.fileStatus;
  const lastModifiedString =
    file?.lastModifiedDate && file?.id !== -1 && file?.fileStatus === "Sent"
      ? `. ${toLocaleDateTimeString(file?.lastModifiedDate)}`
      : "";
  const fileInfo = `${formatSize(
    file?.fileSize || 0
  )} . ${fileStatus} ${lastModifiedString}`;
  return (
    <Stack
      direction="row"
      alignItems="center"
      paddingY={1}
      paddingX={3}
      gap={2}
    >
      {fileIcon}
      <Stack flex={1}>
        <Typography color={error ? "error" : "text.primary"} variant="body1">
          {file?.baseFile?.name}
        </Typography>
        <Typography color={error ? "error" : "text.secondary"} variant="body2">
          {error ? error : fileInfo}
        </Typography>
        {fileStatus === "uploading" && file?.uploadProgress && (
          <LinearProgress
            variant="determinate"
            value={file?.uploadProgress}
            sx={{ height: "4px", width: "200px" }}
          />
        )}
      </Stack>
      {isValid && openPreview && (
        <Button variant="text" onClick={() => openPreview(file)}>
          Preview
        </Button>
      )}
      {isEditMode && (
        <IconButton onClick={() => onDelete(file)} size="small">
          <DeleteIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default OrderTrackingFileInfo;
