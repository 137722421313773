import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import FileInfo from "./OrderTrackingFileInfo";
import { UploadedFile } from "./Interface";

interface Props {
  title: string;
  subtitle?: string;
  emptyListText?: string;
  fileIcon: React.ReactNode;
  files: UploadedFile[];
  deleteFile: (file: UploadedFile, index: number) => void;
  filesAreValid?: boolean;
  itemIdBase: string;
  avatar?: React.ReactNode;
  preview?: (file: UploadedFile, onClose: () => void) => React.ReactNode;
  isEditMode?: boolean;
}

const OrderTrackingFileList = ({
  fileIcon,
  avatar,
  files,
  deleteFile,
  filesAreValid = false,
  title,
  subtitle,
  itemIdBase,
  emptyListText,
  preview,
  isEditMode,
}: Props) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState<UploadedFile | null>(null);
  return (
    <Card elevation={2}>
      <CardHeader
        title={title}
        subheader={subtitle}
        avatar={avatar}
        titleTypographyProps={{ variant: "body1" }}
        subheaderTypographyProps={{ variant: "body2" }}
      ></CardHeader>
      <CardContent sx={{ padding: "16px" }}>
        <Stack>
          {files.length === 0 && (
            <Typography variant="body1">{emptyListText}</Typography>
          )}
          {files.map((file, index) => (
            <FileInfo
              fileIcon={fileIcon}
              key={`${itemIdBase}-${index}`}
              file={file}
              onDelete={(file) => deleteFile(file, index)}
              isValid={filesAreValid}
              openPreview={(file) => {
                setPreviewFile(file);
                setPreviewOpen(true);
              }}
              isEditMode={isEditMode}
            />
          ))}
        </Stack>
      </CardContent>
      {previewOpen &&
        preview &&
        previewFile &&
        preview(previewFile, () => setPreviewOpen(false))}
    </Card>
  );
};

export default OrderTrackingFileList;
