import TargetObjectiveTable, {
  TargetObjectDataTableRefObject,
} from "./TargetObjectiveTable";
import { GridValidRowModel } from "@mui/x-data-grid";
import {
  TargetObjectiveOut,
  useWebApiV1ListTargetObjectivesQuery,
  useWebApiV1UpdateTargetObjectiveMutation,
} from "@providers/hop-ord-server/api";
import { QueryStatus } from "@reduxjs/toolkit/query/react";
import React, { Ref, useImperativeHandle } from "react";
import { useEffect } from "react";

interface TargetObjectiveTableProps {
  isReadOnly: boolean;
  siteId: number;
  siteName: string;
  doseLength: number;
}

export interface TargetObjectiveTableApiRefObject {
  trigger: () => boolean;
}

/*
Not thrilled with this file, but splitting this out from the actual table was the
neatest way I could think of to allow for easy jest testing of the table without
having to worry about mocking the api endpoints
*/

const TargetObjectiveTableApi = React.forwardRef(
  (
    props: TargetObjectiveTableProps,
    ref: Ref<TargetObjectiveTableApiRefObject>
  ) => {
    const tableRef = React.useRef<TargetObjectDataTableRefObject>(null);
    const { siteId } = props;

    const { data, isLoading, error, refetch } =
      useWebApiV1ListTargetObjectivesQuery({
        siteId: Number(siteId),
      });
    const [updateTargetObjective, { status }] =
      useWebApiV1UpdateTargetObjectiveMutation();

    const updateRow = (targetObjective: GridValidRowModel): boolean => {
      updateTargetObjective({
        targetObjectiveId: Number(targetObjective.id),
        siteId,
        targetObjectiveIn: targetObjective as TargetObjectiveOut,
      });
      return true;
    };

    useEffect(() => {
      refetch();
    }, [props?.doseLength]);

    useImperativeHandle(ref, () => ({
      trigger: (): boolean => {
        return (
          (tableRef.current?.trigger() && status !== QueryStatus.pending) ||
          false
        );
      },
    }));

    if (isLoading) return <>Loading</>;
    if (error) return <>Error</>;

    return (
      <TargetObjectiveTable
        dataTestId={`target-objective-table-${siteId}`}
        data={data || []}
        updateRow={updateRow}
        {...props}
        ref={tableRef}
      />
    );
  }
);

export default TargetObjectiveTableApi;
