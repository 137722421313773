import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface DiscardDialogProps {
  open: boolean;
  handleCancel: (open: boolean) => void;
  handleDiscard: () => void;
}

const DiscardDialog = ({
  open,
  handleCancel,
  handleDiscard,
}: DiscardDialogProps) => {
  return (
    <Dialog open={open} data-testid="discard-dialog" maxWidth="xs">
      <DialogTitle>
        <Typography typography="h6">Discard changes?</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to discard your changes and revert to the last
          submitted version?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleCancel(false)}
          color="primary"
          size="medium"
        >
          Cancel
        </Button>
        <Button onClick={handleDiscard} color="error" size="medium">
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscardDialog;
