import { useState } from "react";
import {
  GridRowId,
  useGridApiContext,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { first } from "lodash";
import SiteModal from "@pages/Order/components/SiteNameModal/SiteNameModal";

interface Props {
  options: string[];
  value?: (string | null | undefined)[];
  id: GridRowId;
  field: string;
  row: GridValidRowModel;
  updateSite: (site: GridValidRowModel) => boolean;
}

export const SiteEditField = ({ id, field, value, options }: Props) => {
  const [open, setOpen] = useState(true);
  const apiRef = useGridApiContext();

  // Should always be two items [name, nameSpecify] as the data table likes arrays
  const lengthOfSiteNameArray = 2;
  // First item from the list is the name
  const name = first(value) || "";
  // value[1] can be undefined because of upsteam jank, if it is make it null
  const nameSpecify =
    value?.length == lengthOfSiteNameArray ? value[1] || "" : "";

  const update = (name: string, nameSpecify: string): void => {
    apiRef?.current.setEditCellValue({
      id,
      field,
      value: [name, nameSpecify],
    });
    setOpen(false);
    apiRef.current.stopCellEditMode({ id, field });
  };

  const dismiss = () => {
    apiRef.current.stopCellEditMode({ id, field });
  };

  return open ? (
    <SiteModal
      open={true}
      onDismiss={dismiss}
      onSubmit={update}
      options={options}
      editValues={{ name, nameSpecify: nameSpecify }}
    />
  ) : null;
};
