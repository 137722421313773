import { useGridApiContext } from "@mui/x-data-grid";
import { CustomTextEditCellParams } from "./Interface";
import { CustomTheme, TextField, useTheme } from "@mui/material";

export const CustomCellTextField = (props: CustomTextEditCellParams) => {
  const apiRef = useGridApiContext();
  const theme = useTheme<CustomTheme>();
  const { id, value, multiline, field, readOnly, dataTestId } = props;

  return (
    <TextField
      data-testid={dataTestId}
      value={value !== null ? value : ""}
      multiline={multiline}
      disabled={readOnly}
      sx={{
        width: "100%",
        "& .MuiInputBase-root": {
          padding: "8px !important",
          borderRadius: "0px",
        },
        "& fieldset": { border: "none" },
        "& input": {
          fontSize: `${theme.datagrid.cell.fontSize} !important`,
          paddingLeft: "0px !important",
        },
        "& textarea": {
          fontSize: `${theme.datagrid.cell.fontSize} !important`,
          paddingLeft: "0px !important",
        },
        "& :disabled": {
          WebkitTextFillColor: theme.palette.text.primary,
        },
      }}
      inputProps={{
        onKeyDown: (event) => {
          if (multiline && event.key === "Enter") {
            apiRef.current.setEditCellValue({
              id,
              field,
              value: event.currentTarget.value,
            });
            event.stopPropagation();
          }
        },
      }}
      placeholder={props.placeholder || ""}
      onChange={(event) => {
        const value = event.target.value;
        apiRef.current.setEditCellValue({
          id,
          field,
          value: value,
        });
      }}
    />
  );
};
