import {
  Stack,
  Typography,
  Skeleton,
  Card,
  CardHeader,
  Avatar,
} from "@mui/material";
import { toLocaleDateTimeString } from "@utils";
import { FileAttachmentOut } from "@providers/hop-ord-server/api";
import { Scorecard } from "@components";
import { getScorecardSummary } from "@components/Scorecard/utils";
import { theme } from "@theme";
import ScorecardFileIcon from "@components/Icons/ScorecardFileIcon";

interface Props {
  scorecards: FileAttachmentOut[];
  isLoading: boolean;
}

const ScorecardDisplayPanel = ({ scorecards, isLoading }: Props) => {
  if (isLoading) {
    return (
      <Stack flex={1} gap={1} data-testid="loading-scorecard-display">
        <Skeleton variant="text" width={200} height={45} />
        <Skeleton variant="rounded" height={245} />
        <Skeleton
          variant="rounded"
          height={245}
          sx={{ bgcolor: theme.palette.skeleton.levelTwo }}
        />
      </Stack>
    );
  }
  return (
    <>
      <Stack spacing={2} marginBottom={2}>
        {scorecards.map((data: FileAttachmentOut, index: number) => {
          const fileNameWithoutExtension = data?.fileName
            .split(".")
            .slice(0, -1)
            .join(".");
          return (
            <Card key={`scorecard-display-${index}`}>
              <CardHeader
                title={fileNameWithoutExtension}
                avatar={
                  <Avatar>
                    <ScorecardFileIcon color={theme.palette.primary.main} />
                  </Avatar>
                }
                subheader={getScorecardSummary(data?.scorecardObj)}
              />
              <Stack spacing={1} padding={2}>
                <Scorecard data={data?.scorecardObj} />
                <Typography
                  variant="body2"
                  color={theme.palette.text.secondary}
                >
                  {`Plan locked: ${
                    data.planLockedTimestamp || "Not recorded"
                  } • Uploaded: ${toLocaleDateTimeString(
                    data.createdAt.toString()
                  )}`}
                </Typography>
              </Stack>
            </Card>
          );
        })}
        {scorecards?.length === 0 && (
          <Typography paddingTop={1}>No scorecards added</Typography>
        )}
      </Stack>
    </>
  );
};

export default ScorecardDisplayPanel;
