import { Skeleton, Stack } from "@mui/material";

const PatientDetailsFormSkeleton = () => {
  return (
    <Stack data-testid="loading-patient-details">
      {[...Array(3).keys()].map((n) => (
        <Stack
          direction="row"
          height={64}
          justifyContent="flex-start"
          gap={2}
          alignItems="center"
          key={n}
        >
          <Skeleton sx={{ flex: 1 }} variant="rounded" height={48} />
          <Skeleton sx={{ flex: 1 }} variant="rounded" height={48} />
        </Stack>
      ))}
    </Stack>
  );
};

export default PatientDetailsFormSkeleton;
