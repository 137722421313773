import { FilterList } from "@mui/icons-material";
import {
  Button,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Stack,
  Badge,
} from "@mui/material";
import { useState, useRef, useEffect } from "react";

interface FilterSelectProps {
  label: string;
  options: string[];
  onChange: (values: string[]) => void;
  defaultUnchecked?: string[];
  showBadge?: boolean;
  showFilterList?: boolean;
}

const FilterSelect = ({
  label,
  options,
  onChange,
  defaultUnchecked,
  showBadge = false,
  showFilterList = true,
}: FilterSelectProps) => {
  const [filters, setFilters] = useState(new Set(options));
  const [open, setOpen] = useState(false);
  const [badgeCount, setBadgeCount] = useState(0);
  const targetRef = useRef(null);

  useEffect(() => {
    setFilters(
      new Set(options.filter((option) => !defaultUnchecked?.includes(option)))
    );
  }, [options, defaultUnchecked, onChange]);

  useEffect(() => {
    setBadgeCount(filters.size);
  }, [filters]);

  const handleClick = (v: string) => {
    const newFilters = new Set(filters);
    if (filters.has(v)) {
      newFilters.delete(v);
    } else {
      newFilters.add(v);
    }
    setFilters(newFilters);
    onChange([...newFilters.keys()]);
  };

  const handleAll = () => {
    if (filters.size === options.length) {
      // unselect everything
      setFilters(new Set());
      onChange([]);
    } else {
      // select everything
      setFilters(new Set(options));
      onChange([...options]);
    }
  };

  const getFilterText = () => {
    if (filters.size === options.length) {
      return "all";
    } else if (filters.size === 0) {
      return "none";
    } else {
      return [...filters.keys()].map((v) => ` ${v}`).toString();
    }
  };
  const additionalText = showFilterList ? `: ${getFilterText()}` : "";
  return (
    <>
      <Button
        ref={targetRef}
        onClick={() => setOpen(true)}
        startIcon={
          showBadge ? (
            <Badge badgeContent={badgeCount} color="primary">
              <FilterList />
            </Badge>
          ) : (
            <FilterList />
          )
        }
      >
        {`${label}${additionalText}`}
      </Button>
      <Menu
        anchorEl={targetRef.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Stack overflow="auto" maxHeight={400}>
          {options.map((v) => (
            <MenuItem key={v} onClick={() => handleClick(v)}>
              <Checkbox checked={filters.has(v)} />
              <ListItemText primary={v} />
            </MenuItem>
          ))}
        </Stack>
        <Divider />
        <MenuItem key="__all__" onClick={handleAll}>
          <Checkbox checked={filters.size === options.length} />
          <ListItemText
            primary={
              filters.size === options.length ? "Unselect all" : "Select all"
            }
          />
        </MenuItem>
      </Menu>
    </>
  );
};

export default FilterSelect;
