import React, { useEffect, useRef, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ErrorIcon from "@mui/icons-material/Error";
import {
  Stack,
  TextField,
  Skeleton,
  Grid,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
} from "@mui/material";
import { Button } from "@components";
import ImageBox from "@components/ImageBox/ImageBox";
import { uploadToS3 } from "@utils/s3";
import {
  UploadPayload,
  useCommentsApiV1CreateAttachmentMutation,
} from "@providers/hop-ord-server/api";

interface ISendComment {
  isLoading: boolean;
  sendComment: (comment: string, fileIDs: number[]) => void;
  orderGroupId: number;
}

const SendComment = ({
  isLoading,
  sendComment,
  orderGroupId,
}: ISendComment): JSX.Element => {
  const [comment, setComment] = useState("");
  const [storedFiles, setStoredFiles] = useState<any[]>([]);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [attachmentIds, setAttachmentIds] = useState<number[]>([]);
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [pendingUploads, setPendingUploads] = useState<number>(0);
  const [failedUploadModalOpen, setFailedUploadModalOpen] =
    useState<boolean>(false);
  const [failedUploads, setFailedUploads] = useState<string[]>([]);

  const [createNewAttachment] = useCommentsApiV1CreateAttachmentMutation();

  const MB = 1048576;
  const max_filesize_mb = 3 * MB;

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newFiles: FileList | null = (event.target as HTMLInputElement).files;
    const newPromises: { promise: Promise<void>; fileName: string }[] = [];
    const allowedFileTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/mov",
      "image/mp4",
    ];

    if (newFiles && newFiles.length > 0) {
      Array.from(newFiles).map((file: File) => {
        if (file) {
          if (!allowedFileTypes.includes(file.type)) {
            alert("Unsupported file type");
            return null;
          }

          if (file.size > max_filesize_mb) {
            alert("File too big");
            return null;
          }
          const newPromise = createAttachment(file).finally(() => {
            setStoredFiles((prevFiles) => {
              return [...prevFiles, file];
            });
            setImageUrls((prevUrls) => {
              return [...prevUrls, URL.createObjectURL(file)];
            });
            setPendingUploads((prevPending) => prevPending - 1);
          });
          newPromises.push({ promise: newPromise, fileName: file.name });
        }
      });
    }

    setPendingUploads(newPromises.length);

    await Promise.allSettled(newPromises.map((p) => p.promise)).then(
      (results) => {
        const failedUploads = results
          .map((result, index) => ({
            ...result,
            fileName: newPromises[index].fileName,
          }))
          .filter((result) => result.status === "rejected")
          .map((result) => result.fileName);

        setFailedUploads(failedUploads);

        if (failedUploads.length > 0) {
          setFailedUploadModalOpen(true);
        }
      },
    );
  };

  const createAttachment = async (file: File) => {
    if (!file) return;

    try {
      const res = await createNewAttachment({
        orderGroupId: orderGroupId,
        attachmentIn: {
          fileName: file.name,
        },
      });

      const payload = (res as unknown as { data: UploadPayload }).data;
      await uploadToS3(payload, file);
      setAttachmentIds((prevIds) => {
        if (typeof payload.attachment_id === "number") {
          return [...prevIds, payload.attachment_id];
        } else {
          return prevIds;
        }
      });
    } catch (error) {
      alert("Could not create attachment");
    }
  };

  const sendDisabled = !comment && storedFiles.length === 0;

  // Detect unsaved changes to TextField or storedFiles and warn user on page refresh or close
  // back button is usually not detectable
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (comment || storedFiles.length > 0) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [comment, storedFiles]);

  const clearInputs = () => {
    setComment("");
    setImageUrls([]);
    setStoredFiles([]);
    setAttachmentIds([]);
  };

  const deletePendingAttachment = (index: number) => {
    const newStoredFiles = [...storedFiles];
    newStoredFiles.splice(index, 1);
    setStoredFiles(newStoredFiles);

    const newImageUrls = [...imageUrls];
    newImageUrls.splice(index, 1);
    setImageUrls(newImageUrls);

    const newAttachmentIds = [...attachmentIds];
    newAttachmentIds.splice(index, 1);
    setAttachmentIds(newAttachmentIds);
  };

  if (isLoading) {
    return (
      <Stack gap={2} padding={1} data-testid="loading-send-comment">
        <Skeleton variant="rounded" width="100%" height={68} />
        <Stack direction="row" justifyContent="space-between">
          <Skeleton variant="rounded" width={180} height={38} />
          <Skeleton variant="rounded" width={100} height={38} />
        </Stack>
      </Stack>
    );
  }

  return (
    <>
      <Stack paddingTop={2} spacing={1}>
        {/* Text Input */}
        <TextField
          variant="outlined"
          fullWidth
          multiline={true}
          value={comment}
          label="Add a comment.."
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setComment(event.currentTarget.value);
          }}
          data-testid="comment-input-text"
        />

        {/* Pending Uploads */}
        {pendingUploads > 0 && (
          <Grid container spacing={0.5}>
            {Array.from({ length: pendingUploads }, (_, index) => (
              <Grid item key={`image-url-${index}`} xs={6}>
                <Stack
                  key={index}
                  bgcolor="rgba(0, 0, 0, 0.50)"
                  width="100%"
                  height={240}
                  justifyContent="center"
                  alignItems="center"
                  style={{ color: "white" }}
                >
                  <CircularProgress color="inherit" />
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ position: "absolute" }}
                  >
                    <g id="PhotoOutlined">
                      <path
                        id="Vector"
                        d="M50.6667 13.3333V50.6667H13.3333V13.3333H50.6667ZM50.6667 8H13.3333C10.4 8 8 10.4 8 13.3333V50.6667C8 53.6 10.4 56 13.3333 56H50.6667C53.6 56 56 53.6 56 50.6667V13.3333C56 10.4 53.6 8 50.6667 8ZM37.7067 31.6267L29.7067 41.9467L24 35.04L16 45.3333H48L37.7067 31.6267Z"
                        fill="black"
                        fillOpacity="0.38"
                      />
                    </g>
                  </svg>
                </Stack>
              </Grid>
            ))}
          </Grid>
        )}

        {/* Attachment Previews */}
        {imageUrls.length > 0 && (
          <>
            <Typography variant="subtitle2">
              Attachments ({imageUrls.length})
            </Typography>
            <Grid container spacing={0.5}>
              {storedFiles.map((file, index) => {
                return (
                  <Grid item key={`image-url-${index}`} xs={6}>
                    <ImageBox
                      fileName={file.name}
                      url={imageUrls[index]}
                      onDelete={() => deletePendingAttachment(index)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}

        {/* Buttons */}
        <Stack direction="row" justifyContent="space-between">
          <Button
            variant="text"
            startIcon={<AttachFileIcon />}
            onClick={() => inputFile?.current?.click()}
          >
            Add Attachments
            <input
              type="file"
              data-testid="file-input"
              ref={inputFile}
              onChange={handleFileChange}
              accept="image/*"
              hidden
              multiple={true}
            />
          </Button>
          <Stack direction="row" spacing={1} alignItems="center">
            <Button
              disabled={sendDisabled}
              onClick={clearInputs}
              variant="outlined"
              data-testid="cancel-comment-button"
              fullWidth
            >
              Cancel
            </Button>
            <Button
              disabled={sendDisabled}
              onClick={() => {
                sendComment(comment, attachmentIds);
                clearInputs();
              }}
              variant="contained"
              data-testid="send-comment-button"
              fullWidth
            >
              Post
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Dialog
        open={failedUploadModalOpen}
        onClose={() => setFailedUploadModalOpen(false)}
        data-testid="failed-upload-modal"
      >
        <DialogTitle display="flex" gap={1} alignItems="center">
          <ErrorIcon color="warning" />
          Unable to attach
        </DialogTitle>
        <DialogContent>
          An error occurred while uploading the following files:
          <ul>
            {failedUploads.map((fileName, index) => (
              <li key={`failed-upload-${index}`}>{fileName}</li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="primary"
            onClick={() => setFailedUploadModalOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SendComment;
