import { useEffect, useState } from "react";
import { UploadedFile } from "./Interface";
import { Stack, useTheme } from "@mui/system";
import { IconButton, Modal, Slide, Typography } from "@mui/material";
import { toLocaleDateTimeString } from "@utils";
import { Close as CloseIcon } from "@mui/icons-material";

export const WalkthroughPreviewModal = ({
  file,
  onClose,
}: {
  file: UploadedFile;
  onClose: () => void;
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setPreviewOpen(true);
  }, [file]);

  return (
    <Modal open={previewOpen} onClose={() => setPreviewOpen(false)}>
      <Slide in={previewOpen} direction="up">
        <Stack padding={2} height={1} bgcolor={theme.palette.grey[900]}>
          <Stack direction="row" color="white" justifyContent="space-between">
            <Stack>
              <Typography>{file?.baseFile.name}</Typography>
              <Typography>
                {file && Math.round((file?.fileSize || 0) / 1024)}KB •{" "}
                {file?.fileStatus}:{" "}
                {file?.id !== -1 &&
                  toLocaleDateTimeString(file?.lastModifiedDate.toString())}
              </Typography>
            </Stack>
            <IconButton
              onClick={() => {
                setPreviewOpen(false);
                onClose();
              }}
              color="inherit"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack justifyContent="center" alignItems="center" height={1}>
            {file && file.id === -1 ? (
              <video controls autoPlay={true}>
                <source
                  src={URL.createObjectURL(file.baseFile)}
                  type="video/mp4"
                />
              </video>
            ) : (
              <video controls autoPlay={true} src={file.fileUrl} />
            )}
          </Stack>
        </Stack>
      </Slide>
    </Modal>
  );
};
