import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import PlanningTemplateApi from "./PlanningTemplateApi";
import StatusPage from "./StatusPage";

interface Props {
  orderId: number;
  setCustomStatus: (status: string) => void;
}

const PlanAutomationPanel = ({
  orderId,
  setCustomStatus,
}: Props): JSX.Element => {
  const [status, setStatus] = useState<string>("templating");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [status]);

  return (
    <Stack width={1} height={1} marginTop={2} marginBottom={2} gap={2}>
      {status === "templating" && (
        <PlanningTemplateApi
          orderId={orderId}
          setOrderStatus={setStatus}
          setCustomStatus={setCustomStatus}
        />
      )}
      {status === "submitted" && (
        <StatusPage setCustomStatus={setCustomStatus} />
      )}
    </Stack>
  );
};

export default PlanAutomationPanel;
