import { useGridApiContext } from "@mui/x-data-grid";
import { CustomTextEditCellParams } from "./Interface";

export const CustomCellDisplay = (props: CustomTextEditCellParams) => {
  const apiRef = useGridApiContext();
  const { value, placeholder, id, field } = props;
  const error =
    apiRef.current.getRow(id)?.touched?.includes(field) &&
    (apiRef.current.getRow(id)?.errors?.includes(field) || value === "");

  return (
    <div
      className={error ? "Mui-error" : ""}
      style={{
        padding: "0px 8px",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {value || placeholder}
    </div>
  );
};
