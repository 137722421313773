import { Box, Skeleton, Stack } from "@mui/material";

const SiteGroupPanelSkeleton = () => {
  // Creates a skeleton version of a site group panel, including bolus and OARs
  const siteTableColumns = 10;
  const oarRows = 5;
  return (
    <Stack data-testid="loading-site-group-panel">
      {/* Heading */}
      <Skeleton variant="text" width={200} height={50} />

      <Stack padding={2}>
        {/* Site Name */}
        <Skeleton variant="text" width={80} height={25} />
        <Skeleton variant="text" width={120} height={20} />

        {/* Site Table */}
        <Stack padding={2} paddingTop={0} gap={2}>
          {/* Site Table Header row */}
          <Stack direction="row" gap={2}>
            {new Array(siteTableColumns).fill(null).map((_i, i) => (
              <Skeleton
                key={i}
                variant="text"
                height={36}
                style={{ flex: 1 }}
              />
            ))}
          </Stack>

          {/* Site Table Header row */}
          <Stack direction="row" gap={2}>
            {new Array(siteTableColumns).fill(null).map((_i, i) => (
              <Skeleton
                key={i}
                variant="rounded"
                height={36}
                style={{ flex: 1 }}
              />
            ))}
          </Stack>
        </Stack>
        {/* Add Treatment Site button */}
        <Skeleton variant="rounded" height={36} width={210} />
      </Stack>

      {/* Bolus */}
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Skeleton width={200} height={50} />
          <Skeleton width={70} height={50} />
        </Stack>
        <Stack gap={2} p={2}>
          <Stack direction={{ sm: "column", md: "row" }} gap={2}>
            <Skeleton sx={{ flex: 1 }} variant="rounded" height={48} />
            <Skeleton sx={{ flex: 1 }} variant="rounded" height={48} />
          </Stack>
          <Stack direction={{ sm: "column", md: "row" }} gap={2}>
            <Skeleton sx={{ flex: 1 }} variant="rounded" height={48} />
            <Box sx={{ flex: 1 }} />
          </Stack>
        </Stack>
      </Stack>

      {/* OARs */}
      <Stack padding={2} gap={1}>
        {/* Heading */}
        <Skeleton variant="text" width={150} height={48} />

        {/* Table */}
        {new Array(oarRows).fill(null).map((_i, i) => (
          <Stack
            direction="row"
            key={i}
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Skeleton variant="text" width={100} height={32} />
            <Skeleton variant="rounded" width={100} height={48} />
            <Skeleton variant="circular" width={30} height={30} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default SiteGroupPanelSkeleton;
