import { Role } from "@enums";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface Auth0State {
  isAuthenticated: boolean;
  user: string;
  token: string;
  idToken: string;
  roles: Role[];
  tenants: string[];
}

const initialState: Auth0State = {
  isAuthenticated: false,
  user: "",
  token: "",
  idToken: "",
  roles: [],
  tenants: [],
};

export const auth0Slice = createSlice({
  name: "auth0",
  initialState,
  reducers: {
    setCredentials(
      state,
      action: PayloadAction<{
        user: string;
        token: string;
        idToken: string;
        roles: Role[];
        tenants: string[];
      }>,
    ) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.idToken = action.payload.idToken;
      state.roles = action.payload.roles;
      state.tenants = action.payload.tenants;
    },
    logOut(state) {
      state.isAuthenticated = false;
      state.user = "";
      state.token = "";
      state.idToken = "";
      state.roles = [];
      state.tenants = [];
    },
  },
});

export const { setCredentials, logOut } = auth0Slice.actions;
export default auth0Slice.reducer;
