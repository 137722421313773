import { Autocomplete, CustomTheme, TextField, useTheme } from "@mui/material";
import {
  GridRenderEditCellParams,
  GridSingleSelectColDef,
  ValueOptions,
  useGridApiContext,
} from "@mui/x-data-grid";
import React, { useEffect } from "react";

export const CustomCellAutoComplete = (props: GridRenderEditCellParams) => {
  const [open, setOpen] = React.useState(false);
  const apiRef = useGridApiContext();
  const theme = useTheme<CustomTheme>();
  const { id, value, field, colDef } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [options, setOptions] = React.useState<ValueOptions[]>([]);
  useEffect(() => {
    if ((colDef as GridSingleSelectColDef).valueOptions) {
      setOptions([
        "",
        ...((colDef as GridSingleSelectColDef).valueOptions as ValueOptions[]),
      ]);
    }
  }, [colDef]);
  const error =
    apiRef.current.getRow(id)?.touched?.includes(field) &&
    (apiRef.current.getRow(id)?.errors?.includes(field) || value === "");

  return (
    <Autocomplete
      data-testid={props.dataTestId}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      fullWidth
      disableClearable
      forcePopupIcon={false}
      sx={{
        "& .MuiAutocomplete-root": {},
        width: "100%",
        height: "100%",
        "& .MuiFormControl-root": {
          height: "100%",
        },
        "& .MuiInputBase-root": {
          height: "100%",
          borderRadius: "0px",
        },
      }}
      disabled={props.readOnly}
      value={value}
      options={options}
      isOptionEqualToValue={(option, value) => option === value}
      renderInput={(params) => (
        <TextField
          inputRef={inputRef}
          {...params}
          sx={{
            fontSize: theme.datagrid.cell.fontSize,
            "& fieldset": { border: "none" },
            "& textarea": {
              padding: "0px 0px !important",
              fontSize: theme.datagrid.cell.fontSize,
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: theme.palette.text.primary,
            },
          }}
          onKeyDown={() => {
            if (!open) {
              setOpen(true);
            }
          }}
          disabled={props.readOnly}
          error={error}
          placeholder={props.placeholder || ""}
          multiline={true}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      componentsProps={{
        paper: {
          sx: {
            width: "max-content",
            fontSize: theme.datagrid.cell.fontSize,
          },
        },
      }}
      onChange={(_event, value) => {
        // only update in the value is in the list of options
        if (
          (
            ((props.colDef as GridSingleSelectColDef).valueOptions ||
              []) as ValueOptions[]
          ).includes(value)
        ) {
          apiRef.current.setEditCellValue({ id, field, value });
          apiRef.current.stopCellEditMode({ id, field });
          // set the field as touched and update the value
          const row = apiRef.current.getRow(id);
          apiRef.current.updateRows([
            {
              id,
              errors: [],
              touched: row?.touched
                ? [...new Set([...row.touched, field])]
                : [field],
              [field]: value,
            },
          ]);
        }
      }}
      onBlur={() => {
        const isValueInOptions = (
          ((props.colDef as GridSingleSelectColDef).valueOptions ||
            []) as ValueOptions[]
        ).includes(value);
        // set the field as touched and update the value
        const row = apiRef.current.getRow(id);
        apiRef.current.updateRows([
          {
            id,
            touched: row?.touched
              ? [...new Set([...row.touched, field])]
              : [field],
            [field]: isValueInOptions ? value : "",
          },
        ]);
      }}
    />
  );
};
