import {
  Card,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { Button, PageHeader } from "@components";
import { useNavigate } from "react-router-dom";
import {
  CheckCircle,
  FilterAlt,
  LocationCity,
  PlaylistAddCheck,
  Warning,
  Cancel,
  ArrowDownward,
  Sync,
} from "@mui/icons-material";
import { theme } from "@theme";
import { toLocaleDateTimeString } from "@utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { useWebApiV1ListOrdersQuery } from "@providers/hop-ord-server/api";
import { CCOrder, mockOrders, ONE_DAY, statusMessages } from "./mockData";

const shortDate = (date: Date) => {
  return `${date.toLocaleString("default", { month: "short" })}-${date.getDate()}`;
};

const getPlanDeliveryText = (planDelivery: string) => {
  const getColor = () => {
    switch (planDelivery) {
      case "Emergency":
        return "error";
      case "Urgent":
        return "warning.dark";
      default:
        return "textPrimary";
    }
  };

  return (
    <Typography variant="body2" color={getColor()}>
      {planDelivery}
    </Typography>
  );
};

const getElementColor = (status: string) => {
  if (status === "complete") {
    return "success";
  }

  if (status === "in-progress") {
    return "secondary";
  }

  return "primary";
};

const getBackgroundColor = (status: string) => {
  switch (status) {
    case "complete":
      return "#ECF9F4";
    case "in-progress":
      return "#FAFAFF";
    case "pending":
      return "#FFF8EF";
    case "rejected":
      return "#FFF0F1";
    default:
      return theme.palette.grey[200];
  }
};

const StatusIcon = ({
  status,
  fontSize = "inherit",
}: {
  status: string;
  fontSize?: string;
}): JSX.Element => {
  if (status === "complete") {
    return <CheckCircle color="success" sx={{ fontSize }} />;
  }

  if (status === "in-progress") {
    return <Sync color="secondary" sx={{ fontSize }} />;
  }

  if (status === "pending") {
    return <Warning color="warning" sx={{ fontSize }} />;
  }

  if (status === "rejected") {
    return <Cancel color="error" sx={{ fontSize }} />;
  }
  return <></>;
};

const upperFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const CommandCenter = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState<CCOrder[]>(mockOrders);
  const [dataReady, setDataReady] = useState(false);

  const { data: orderData } = useWebApiV1ListOrdersQuery({
    limit: 20,
  });

  // Adding the existing orders to the state.
  // TODO There are multiple fields that do not yet exist in the API response
  // so we're hardcoding them for now
  // Some of them just exist in subordinate objects that need to be fetched
  useEffect(() => {
    if (orderData?.items) {
      setOrders((prevOrders) => {
        const prevOrderIds = prevOrders.map((order) => order.id);
        const newOrders = orderData.items
          .filter(
            (order) =>
              order.status !== "draft" && !prevOrderIds.includes(order.id),
          )
          .map((order) => ({
            id: order.id,
            patientName: order.patientName || "",
            patientDob: order.patientDob
              ? dayjs(order.patientDob).format("MMM-DD-YYYY")
              : "",
            patientMrn: order.patientMrn || "",
            patientGender: "(M)",
            clinic: "Lakeside Medical Clinic",
            treatmentStart: new Date("2024-09-27T17:30:00"),
            planDelivery: upperFirst(order.planDelivery || ""),
            simulationStatus: "complete",
            simulationCompleteDate: new Date(Date.now() - ONE_DAY * 4),
            contouringStatus: "complete",
            contouringCompleteDate: new Date(Date.now() - ONE_DAY * 3),
            targetStatus: "complete",
            targetCompleteDate: new Date(Date.now() - ONE_DAY * 2),
            reviewStatus: "not-started",
            reviewCompleteDate: null,
            planStatus: {
              status: "ready",
              name: null,
              planType: null,
              statusMessage: null,
              progress: 0,
              step: 0,
            },
          }));

        // Insert real orders in the middle of the pack
        const insertIndex = 4;
        return [
          ...prevOrders.slice(0, insertIndex),
          ...newOrders,
          ...prevOrders.slice(insertIndex),
        ];
      });
      setDataReady(true);
    }
  }, [orderData]);

  const mockPlanning = (index: number) => {
    const interval = setInterval(
      () => {
        setOrders((prevOrders) => {
          const orderToChange = { ...prevOrders[index] };

          const updatePlanStatus = (statusIndex: number) => {
            const complete = statusIndex === statusMessages.length;
            return {
              ...orderToChange.planStatus,
              statusMessage: complete
                ? `Completed ${new Date().toLocaleString()}`
                : statusMessages[statusIndex],
              status: complete ? "complete" : "in-progress",
              progress: statusIndex * 10,
              step: statusIndex,
            };
          };

          if (
            orderToChange.planStatus.status === "not-started" ||
            orderToChange.reviewStatus === "complete"
          ) {
            orderToChange.planStatus = updatePlanStatus(0);
            orderToChange.reviewStatus = "not-started";
            orderToChange.reviewCompleteDate = null;
          } else if (orderToChange.planStatus.status === "in-progress") {
            orderToChange.planStatus = updatePlanStatus(
              orderToChange.planStatus.step + 1,
            );
          } else if (orderToChange.reviewStatus === "not-started") {
            orderToChange.reviewStatus = "complete";
            orderToChange.reviewCompleteDate = new Date();
          } else if (orderToChange.reviewStatus === "complete") {
            //return {...mockOrders}
          } else {
            clearInterval(interval);
          }

          // return prevOrders with the order changed at the right index
          return prevOrders.map((order, i) => {
            if (i === index) {
              return orderToChange;
            }
            return order;
          });
        });
      },
      300000 / statusMessages.length + 1000 * index,
    );
  };

  // janky hack because the useEffect is being triggered twice because React is garbage
  const intervalsStarted = useRef(false);

  useEffect(() => {
    if (!intervalsStarted.current) {
      mockPlanning(2);
      mockPlanning(3);
      intervalsStarted.current = true;
    }
  }, []);

  const navToTracker = useCallback(
    (orderId: number) => navigate(`/fulfillment/${orderId}/tracker`),
    [navigate],
  );

  const StatusCell = ({
    status,
    completedDate,
  }: {
    status: string;
    completedDate: Date | null;
  }) => {
    return (
      <TableCell
        sx={{
          backgroundColor: getBackgroundColor(status),
        }}
      >
        <Stack alignItems="center" gap={1}>
          <StatusIcon status={status} fontSize="34px" />
          {completedDate && shortDate(completedDate)}
          {status === "not-started" && (
            <Typography color="text.secondary" variant="body2">
              Not Started
            </Typography>
          )}
          {status === "rejected" && (
            <Typography color="error" variant="body2">
              Rejected
            </Typography>
          )}
          {status === "pending" && (
            <Typography variant="body2">Pending</Typography>
          )}
        </Stack>
      </TableCell>
    );
  };

  if (!dataReady) return null;

  return (
    <Stack gap={3} paddingBottom={2}>
      <PageHeader title="Planning Command Center" />
      <Stack direction="row" alignItems="center" gap={2}>
        <TextField id="standard-basic" label="Search..." variant="standard" />

        <Button
          variant="text"
          startIcon={<PlaylistAddCheck color="secondary" />}
        >
          Status
        </Button>

        <Button variant="text" startIcon={<LocationCity color="secondary" />}>
          Clinic
        </Button>

        <Button variant="text" startIcon={<FilterAlt color="secondary" />}>
          Filter
        </Button>
      </Stack>

      <Card sx={{ overflow: "auto" }}>
        <TableContainer sx={{ overflow: "auto" }}>
          <Table
            size="small"
            sx={{
              minWidth: "1200px",
              "& .MuiTableCell-head": {
                padding: "16px",
              },
              "& .MuiTableCell-body": {
                padding: "8px",
                border: "1px solid #E0E0E0 !important", // important because MUI puts border 0px on the last child
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell width={"15%"}>Patient</TableCell>
                <TableCell width={"10%"}>
                  <Stack direction="row" alignItems="center" gap={0.5}>
                    <ArrowDownward color="secondary" fontSize="small" />{" "}
                    Treatment Start
                  </Stack>
                </TableCell>
                <TableCell width={"5%"} sx={{ textAlign: "center" }}>
                  Simulation
                </TableCell>
                <TableCell width={"5%"} sx={{ textAlign: "center" }}>
                  Contouring
                </TableCell>
                <TableCell width={"5%"} sx={{ textAlign: "center" }}>
                  Target
                </TableCell>
                <TableCell width={"20%"}>Planning Status</TableCell>
                <TableCell width={"6%"} sx={{ textAlign: "center" }}>
                  Plan Review
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {orders.map((order, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {/* Patient */}
                  <TableCell component="th" scope="row">
                    <Typography variant="body2">
                      {order.patientName} {order.patientGender}
                      <br />
                      MRN: {order.patientMrn} | DOB: {order.patientDob}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {order.clinic}
                    </Typography>
                  </TableCell>

                  {/* Treatment Start */}
                  <TableCell
                    sx={{ backgroundColor: getBackgroundColor("complete") }}
                  >
                    <Typography variant="body2">
                      {order.treatmentStart
                        ? toLocaleDateTimeString(order.treatmentStart)
                        : "-"}
                      <br />
                      {getPlanDeliveryText(order.planDelivery)}
                    </Typography>
                  </TableCell>

                  {/* Simulation */}
                  <StatusCell
                    status={order.simulationStatus}
                    completedDate={order.simulationCompleteDate}
                  />

                  {/* Contouring */}
                  <StatusCell
                    status={order.contouringStatus}
                    completedDate={order.contouringCompleteDate}
                  />

                  {/* Target */}
                  <StatusCell
                    status={order.targetStatus}
                    completedDate={order.targetCompleteDate}
                  />

                  {/* Planning Status */}
                  <TableCell
                    sx={{
                      backgroundColor: getBackgroundColor(
                        order.planStatus.status,
                      ),
                    }}
                  >
                    {order.planStatus.status === "not-started" && (
                      <Typography color="text.secondary">
                        Not Started
                      </Typography>
                    )}
                    {order.planStatus.status === "ready" && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => navToTracker(order.id)}
                      >
                        Ready for Planning
                      </Button>
                    )}

                    {order.planStatus.status !== "not-started" &&
                      order.planStatus.status !== "ready" && (
                        <Stack gap={0.5}>
                          <Stack direction={"row"} gap={1}>
                            <StatusIcon
                              status={order.planStatus.status}
                              fontSize="20px"
                            />
                            <Typography variant="body2">
                              {order.planStatus.name}
                            </Typography>
                          </Stack>
                          <LinearProgress
                            color={getElementColor(order.planStatus.status)}
                            variant="determinate"
                            value={order.planStatus.progress}
                          />
                          <Typography variant="body2" color="text.secondary">
                            {order.planStatus.planType} |{" "}
                            {order.planStatus.statusMessage}
                          </Typography>
                        </Stack>
                      )}
                  </TableCell>

                  {/* Plan Review */}
                  <StatusCell
                    status={order.reviewStatus}
                    completedDate={order.reviewCompleteDate}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Stack>
  );
};

export default CommandCenter;
