import { CustomTextEditCellParams } from "./Interface";

export const CustomCellBoldDisplay = (props: CustomTextEditCellParams) => {
  const { value, placeholder } = props;
  return (
    <div
      style={{
        padding: "0px 8px",
        height: "100%",
        display: "flex",
        alignItems: "center",
        fontWeight: "bold",
      }}
    >
      <div>{value || placeholder}</div>
    </div>
  );
};
