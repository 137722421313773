import { Button } from "@components";
import { CheckCircle, Circle, Timelapse } from "@mui/icons-material";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { theme } from "@theme";
import { useEffect, useState } from "react";

type PlanStatus = "pending" | "in-progress" | "complete";
interface DetailsSectionProps {
  title: string;
  status: PlanStatus;
  currentDetail: number;
  details: string[];
}

const mockStatusSections: DetailsSectionProps[] = [
  {
    title: "Preparing order",
    status: "in-progress",
    currentDetail: 0,
    details: [
      "Planning CT and structures have been imported",
      "Fusion data has been imported",
      "Structures processed",
      "Queued for planning",
    ],
  },
  {
    title: "Planning (ETA ~20min)",
    status: "pending",
    currentDetail: -1,
    details: [
      "Planning started",
      "Prescription and OARs have been applied",
      "New plan has been saved",
      "Beginning calculation phase 01",
      "Completed calculation phase 01",
      "Beginning calculation phase 02",
      "Completed calculation phase 02",
      "Completed scorecard data upload",
      "Completed plan walkthrough data upload",
    ],
  },
  {
    title: "Dosimetrist review",
    status: "pending",
    currentDetail: -1,
    details: ["Review started", "Dosimetrist has completed the review"],
  },
  {
    title: "Completed",
    status: "pending",
    currentDetail: -1,
    details: [],
  },
];

const ProgressBar = ({ value, label }: { value: number; label: string }) => {
  return (
    <Box
      sx={{
        height: "28px",
        position: "relative",
        backgroundColor: theme.palette.skeleton.main,
      }}
    >
      <Box
        sx={{
          width: `${value}%`,
          height: "100%",
          backgroundColor: theme.palette.success.main,
          position: "absolute",
          top: 0,
          left: 0,
          transition: "width 0.5s ease-in-out",
        }}
      />

      <Typography
        sx={{
          position: "absolute",
          fontSize: "12px",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

interface Props {
  // TODO: Remove this jank after astro demo
  setCustomStatus: (status: string) => void;
}

const PlanAutomationPanel = ({ setCustomStatus }: Props): JSX.Element => {
  const [autoUpdateStarted, setAutoUpdateStarted] = useState(false);
  const [sections, setSections] = useState(mockStatusSections);
  const [openToast, setOpenToast] = useState(false);
  const [reviewSent, setReviewSent] = useState(false);

  useEffect(() => {
    if (autoUpdateStarted) return;

    const interval = setInterval(() => {
      setSections((prevSections) =>
        prevSections.map((section, index) => {
          if (section.status === "in-progress") {
            if (section.currentDetail < section.details.length) {
              return {
                ...section,
                currentDetail: section.currentDetail + 1,
              };
            } else {
              return {
                ...section,
                status: "complete",
              };
            }
          }

          if (
            index > 0 &&
            section.status === "pending" &&
            prevSections[index - 1].currentDetail ===
              prevSections[index - 1].details.length
          ) {
            return {
              ...section,
              status: "in-progress",
              currentDetail: section.currentDetail + 1,
            };
          }
          return section;
        }),
      );
    }, 1500);

    setAutoUpdateStarted(true);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (sections[sections.length - 1].status === "complete") {
      setCustomStatus("Plan completed");
    }
  }, [sections]);

  return (
    <Card>
      <Stack
        direction="row"
        gap={2}
        padding={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <CardHeader title="Planning Automation Status" />
        <Box>
          <Button
            disabled={reviewSent}
            onClick={() => {
              setOpenToast(true);
              setReviewSent(true);
              setSections((prevSections) =>
                prevSections.map((section) => {
                  return {
                    ...section,
                    status: "complete",
                    currentDetail: section.details.length + 1,
                  };
                }),
              );
              setCustomStatus("Plan review");
            }}
            variant="contained"
          >
            Send for Review
          </Button>
        </Box>
      </Stack>
      <Stack direction="row" gap={2} padding={2}>
        {sections.map((section, index) => (
          <Box key={index} flex={1}>
            <ProgressBar
              value={
                section.status === "complete"
                  ? 100
                  : (section.currentDetail / section.details.length) * 100
              }
              label={section.title}
            />
          </Box>
        ))}
      </Stack>

      <CardHeader title="Show details" />

      <Stack
        paddingLeft={2}
        paddingBottom={2}
        gap={2}
        divider={<Divider flexItem />}
      >
        {sections.map((section, index) => (
          <Stack gap={2} key={index}>
            <Stack direction="row" gap={1}>
              {section.status == "pending" && <Circle color="disabled" />}
              {section.status == "in-progress" && <Timelapse color="success" />}
              {section.status == "complete" && <CheckCircle color="success" />}
              <Typography variant="body1">{section.title}</Typography>
            </Stack>
            {section.details.map((detail, index) => (
              <Typography
                key={index}
                fontWeight={400}
                color={
                  index === section.currentDetail - 1
                    ? "success.dark"
                    : "text.disabled"
                }
              >
                {detail}
              </Typography>
            ))}
          </Stack>
        ))}
      </Stack>
      <Snackbar
        open={openToast}
        onClose={() => setOpenToast(false)}
        autoHideDuration={2000}
        message="Plan successfully sent for review"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      ></Snackbar>
    </Card>
  );
};

export default PlanAutomationPanel;
