import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useWebApiV1DeleteSiteGroupMutation } from "@providers/hop-ord-server/api";

import { SiteGroupOut } from "@providers/hop-ord-server/api";
import { useEffect } from "react";

export interface DeleteSiteGroupModalProps {
  open: boolean;
  orderId: number | undefined;
  setOpen: (value: boolean) => void;
  selectedSiteGroup: SiteGroupOut | null;
  onSiteGroupDeletedCallback: (siteGroupId: number) => void;
}

const DeleteSiteGroupModal = ({
  open,
  setOpen,
  selectedSiteGroup,
  onSiteGroupDeletedCallback,
}: DeleteSiteGroupModalProps) => {
  const [deleteSiteGroupMutation] = useWebApiV1DeleteSiteGroupMutation();

  const confirm = async () => {
    if (!selectedSiteGroup) return;

    const result = await deleteSiteGroupMutation({
      siteGroupId: selectedSiteGroup.id,
    });

    if ("error" in result) {
      alert(JSON.stringify(result.error)); // TODO - change this to a toast or something
    } else {
      setOpen(false);
      onSiteGroupDeletedCallback(selectedSiteGroup.id);
    }
  };

  // this is legit just to keep the scrollbar around when the `disableScrollLock` prop
  // is enabled below
  useEffect(() => {
    if (!open) {
      return;
    }
    const prevent = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    };
    document.body.addEventListener("wheel", prevent, { passive: false });
    return () => {
      document.body.removeEventListener("wheel", prevent);
    };
  }, [open]);

  return (
    <Dialog open={open} disableScrollLock>
      <DialogTitle>Delete site group?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete "{selectedSiteGroup?.name}"? <br />
          This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button variant="text" color="error" onClick={confirm}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSiteGroupModal;
