import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";

import { SiteModalProps } from "./Interface";
import { isSpecifyMandatory } from "@utils";
import {
  AutocompleteElement,
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";

export interface InputTypes {
  name: string;
  nameSpecify: string;
}

const emptySite = {
  name: "",
  nameSpecify: "",
};

const SiteModal = ({
  open,
  options,
  onSubmit,
  onDismiss,
  editValues = emptySite,
}: SiteModalProps) => {
  const form = useForm<InputTypes>({
    defaultValues: editValues,
    mode: "all",
  });

  const stopPropagationForTab = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.stopPropagation();
    }
  };

  const submit: SubmitHandler<InputTypes> = () => {
    const values = form.getValues();
    onSubmit(values.name || "", values.nameSpecify);
  };

  const dismiss = () => {
    onDismiss && onDismiss();
  };

  return (
    <Dialog
      open={open}
      onKeyDown={stopPropagationForTab}
      data-testid="add-site-modal"
    >
      <FormContainer
        formContext={form}
        handleSubmit={form.handleSubmit(submit)}
      >
        <DialogTitle>Select treatment site</DialogTitle>
        <DialogContent>
          <Stack spacing={2} paddingY={1}>
            <AutocompleteElement
              label="Treatment site"
              name="name"
              required
              options={options}
              autocompleteProps={{
                openOnFocus: true,
                style: { width: "400px", maxWidth: "100%" },
                isOptionEqualToValue: (option: string, value: string) =>
                  option === value,
              }}
            />
            <TextFieldElement
              name="nameSpecify"
              label="Additional site information"
              data-testid="add-site-modal-specify"
              rules={{
                required: {
                  value: isSpecifyMandatory(form.watch("name")),
                  message: "This field is required",
                },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={dismiss}>
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Confirm
          </Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
};

export default SiteModal;
