import { Portal } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const PortaledMainBanner = ({ children }: Props) => {
  const container = document.getElementById("main-banner");

  return <Portal container={container}>{children}</Portal>;
};

export default PortaledMainBanner;
