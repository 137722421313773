import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export interface InputTypes {
  rejectReason: string[];
  additionalInfo: string;
}

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  updateOrder: () => void;
}

const AcceptOrderModal = ({ open, setOpen, updateOrder }: Props) => {
  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>Approve order</DialogTitle>
      <DialogContent>
        By approving, you acknowledge no further changes will be made to the
        order and a handover process will be initiated with the local planning
        team.
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button variant="text" color="primary" onClick={updateOrder}>
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptOrderModal;
