import { CircularProgress, Button as MUIButton } from "@mui/material";

interface ButtonProps extends React.ComponentProps<typeof MUIButton> {
  busy?: boolean;
}

export default function Button({ busy, ...props }: ButtonProps) {
  return (
    <MUIButton disabled={busy || props.disabled} {...props}>
      {busy && (
        <CircularProgress
          size={24}
          sx={{ marginRight: "8px", position: "absolute" }}
          color="inherit"
        />
      )}
      <span style={{ visibility: busy ? "hidden" : "inherit" }}>
        {props.children}
      </span>
    </MUIButton>
  );
}
