import { useEffect } from "react";

const SentryError = () => {
  useEffect(() => {
    throw new Error("SentryError");
  }, []);

  return <div>SentryError Page</div>;
};

export default SentryError;
