import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import { doseLabels } from "@utils/doseAndFractions";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { Button } from "@components";
import { RemoveCircle } from "@mui/icons-material";
import { areDosesInOrder } from "@utils";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from "react";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  doseValues: string[];
  onSubmit?: (doseArray: string[]) => void;
  doseUnit: string;
}

export interface InputTypes {
  doseValues: { [key: string]: string }[];
}

export const DoseEditDialog = (props: Props) => {
  const { open, setOpen, onSubmit, doseUnit } = props;

  const form = useForm<InputTypes>({
    values: { doseValues: props.doseValues.map((v) => ({ value: v })) },
  });
  const doseValues = useFieldArray({
    control: form.control,
    name: "doseValues",
    rules: { minLength: 1, maxLength: 4, required: true },
  });

  const submit = () => {
    const doseArray = form.getValues().doseValues.map((v) => v.value);
    const validDoses = areDosesInOrder(doseArray);

    if (validDoses.includes(false)) {
      validDoses.forEach((valid, index) => {
        if (!valid) {
          form.setError(`doseValues.${index}.value`, {
            type: "manual",
            message: "Dose value is not in order from high to low",
          });
        }
      });
      return;
    }

    onSubmit && onSubmit(doseArray);
  };

  const addDose = async () => {
    if (doseValues.fields.length < 2) {
      doseValues.append({ value: "" });
    } else {
      doseValues.insert(-1, { value: "" });
    }
  };

  const removeDose = (index: number) => {
    doseValues.remove(index);
  };

  useEffect(() => {
    if (doseValues.fields.length < 2) {
      doseValues.append({ value: "" });
    }
  }, []);

  const handleClose = () => {
    form.reset({ doseValues: props.doseValues.map((v) => ({ value: v })) });
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onKeyDown={(event) => {
        if (event.key === "Tab") {
          event.stopPropagation();
        }
      }}
    >
      <FormContainer
        formContext={form}
        handleSubmit={form.handleSubmit(submit)}
      >
        <DialogTitle>Simultaneous integrated boost</DialogTitle>

        <DialogContent style={{ paddingTop: "5px" }}>
          <Stack direction="column" spacing={3}>
            {doseValues.fields.map((item, index) => {
              return (
                <TextFieldElement
                  control={form.control}
                  name={`doseValues.${index}.value`}
                  key={item.id}
                  id={`dose-${index}`}
                  label={`${
                    doseLabels[doseValues.fields.length][index]
                  } (${doseUnit})`}
                  autoFocus
                  fullWidth
                  required
                  InputProps={{
                    name: `doseValues.${index}.value`,
                    style: { paddingRight: "4px" },
                    endAdornment: index !== 0 && (
                      <IconButton
                        data-testid={`${
                          doseLabels[doseValues.fields.length][index]
                        }-delete`}
                        onClick={() => removeDose(index)}
                      >
                        <RemoveCircle />
                      </IconButton>
                    ),
                  }}
                />
              );
            })}
            <Button
              disabled={doseValues.fields.length >= 4}
              variant="outlined"
              fullWidth
              onClick={addDose}
              startIcon={<AddIcon />}
            >
              Add dose level
            </Button>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" data-testid="submit-btn">
            Confirm
          </Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
};
