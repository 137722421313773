import { UploadFile as UploadFileIcon } from "@mui/icons-material";
import { Paper, Stack, Typography } from "@mui/material";
import { theme } from "@theme";
import { FileRejection, useDropzone } from "react-dropzone";

const MAX_FILE_SIZE = 104857600; // 100 MB
const ACCEPTED_FILE_TYPES = {
  "application/json": [".json"],
  "video/mp4": [".mp4", ".mov"],
};
const APPROVED_FILES = "json, mp4 or mov (max. 100MB)";

interface DropzoneProps {
  title?: string;
  onDrop: (approvedFiles: File[], rejections: FileRejection[]) => void;
}

const Dropzone = ({ title, onDrop }: DropzoneProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILE_TYPES,
    maxSize: MAX_FILE_SIZE,
    multiple: true,
  });

  return (
    <Stack spacing={1}>
      {title && <Typography variant="h5">{title}</Typography>}
      <Paper
        variant="outlined"
        sx={{
          ":hover": { backgroundColor: theme.palette.action.hover },
          borderStyle: "dashed",
          borderWidth: 1,
          cursor: "pointer",
        }}
        {...getRootProps()}
      >
        <Stack alignItems="center" justifyContent="center" height="152px">
          <input {...getInputProps()} data-testid="drag-and-drop-box" />
          <UploadFileIcon
            style={{
              color: theme.palette.primary.main,
              fontSize: 36,
              paddingBottom: 2,
            }}
          />
          <Stack direction="row" gap={1}>
            <Typography
              variant="body1"
              sx={{
                textDecoration: "underline",
                color: theme.palette.primary.main,
              }}
            >
              Click to upload{" "}
            </Typography>
            <Typography variant="body1">or drag and drop</Typography>
          </Stack>

          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.secondary }}
          >
            {APPROVED_FILES}
          </Typography>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default Dropzone;
