import { debounce } from "lodash";

export const validationErrorSelector = '[aria-invalid="true"]';

export function getFormValidationErrors() {
  return document.querySelectorAll(validationErrorSelector);
}

export function autoScroll(elements: NodeListOf<Element>, active = true) {
  const debouncedScrollLogic = debounce(() => {
    if (active && elements && elements.length > 0) {
      // Scroll to the first error
      elements[0].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
      (elements[0] as HTMLElement).focus({ preventScroll: true });
    }
  }, 200); //stable (with x2 re-renders from parent)

  debouncedScrollLogic();
}
