import {
  ScorecardAttachmentOut,
  FileAttachmentOut,
} from "@providers/hop-ord-server/api";
import { ScorecardObject } from "@providers/hop-ord-server/api";
import scorecardDataValidation from "@components/ScorecardDataValidation/ScorecardDataValidation";
import { UploadedFile } from "./Interface";

export const convertToFile = async (
  data: object,
  name: string
): Promise<File | null> => {
  try {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: "application/json" });
    const file = new File([blob], name, { type: "application/json" });
    return file;
  } catch (error) {
    console.error("Error downloading file:", error);
    return null;
  }
};

export const readJsonFile = (file: File): Promise<ScorecardObject> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const fileContent = event?.target?.result;

      try {
        if (typeof fileContent !== "string") {
          throw new Error("expected fileContent to be string");
        }
        const parsedData = JSON.parse(fileContent);
        resolve(parsedData);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = (event) => {
      reject(event?.target?.error);
    };

    reader.readAsText(file);
  });
};

export const parseJsonDataFromServer = async (
  attachment: ScorecardAttachmentOut
): Promise<UploadedFile[]> => {
  const attachments = await Promise.all(
    attachment.scorecards.map(
      async (scorecard: FileAttachmentOut): Promise<UploadedFile | null> => {
        const name = scorecard?.fileName.split("/").at(-1) || "";
        const jsonData = scorecard.scorecardObj;
        const planLockedTimestamp =
          scorecard.scorecardObj.PlanApproval?.Timestamp;
        const isValid = scorecardDataValidation(jsonData);
        if (!isValid) return null;
        const downloadedFile = await convertToFile(jsonData, name);
        if (downloadedFile === null) return null;
        return {
          id: scorecard.id,
          lastModifiedDate: new Date(scorecard?.lastModifiedDate),
          createdAt: new Date(scorecard?.createdAt),
          baseFile: downloadedFile,
          fileSize: downloadedFile.size,
          fileStatus: "Sent",
          uploadErrors: "",
          planLockedTimestamp,
        } as UploadedFile;
      }
    )
  );
  return attachments.filter(
    (attachment): attachment is UploadedFile => attachment !== null
  );
};
