import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { WalkthroughAttachmentOut } from "@providers/hop-ord-server/api";
import { toLocaleDateTimeString } from "@utils";
import VideoFileIcon from "@mui/icons-material/VideoFile";

interface Props {
  walkthroughs?: WalkthroughAttachmentOut[];
}

const WalkthroughDisplayPanel = ({ walkthroughs }: Props) => {
  const formatFileSize = (bytes: number) => {
    // convert bytes to megabytes or kilobtyes and append the right unit
    if (bytes > 1048576) {
      return `${Math.round((bytes / 1048576) * 100) / 100}MB`;
    } else if (bytes > 1024) {
      return `${Math.round(((bytes / 1024) * 100) / 100)}KB`;
    } else if (bytes > 1) {
      return `${bytes} bytes`;
    } else if (bytes === 1) {
      return `${bytes} byte`;
    }

    return "0 bytes";
  };

  return (
    <Stack gap={1} marginY={1}>
      {walkthroughs?.map((data, index: number) => {
        return (
          <Card
            key={`walkthrough-display-${index}`}
            data-testid={`walkthrough-display-${index}`}
          >
            <CardHeader
              title={data.fileName}
              avatar={
                <Avatar>
                  <VideoFileIcon />
                </Avatar>
              }
              subheader={`${formatFileSize(
                data.fileSize
              )} • ${toLocaleDateTimeString(data.createdAt)}`}
            />
            <CardContent>
              <video style={{ width: "100%", maxHeight: "50vh" }} controls>
                <source
                  data-testid={`walkthrough-video-${index}`}
                  src={`${data.file}`}
                  type="video/mp4"
                ></source>
              </video>
            </CardContent>
          </Card>
        );
      })}

      {walkthroughs?.length === 0 && (
        <Typography>No plan walkthroughs added</Typography>
      )}
    </Stack>
  );
};

export default WalkthroughDisplayPanel;
