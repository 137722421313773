import { CustomTheme, TextField, useTheme } from "@mui/material";
import { NumberEditCellParams } from "./Interface";
import { useGridApiContext } from "@mui/x-data-grid";
import { isNumeric } from "@utils";

export const CustomCellNumberField = (props: NumberEditCellParams) => {
  const apiRef = useGridApiContext();
  const theme = useTheme<CustomTheme>();
  const { id, value, field, numberType } = props;
  const error =
    apiRef.current.getRow(id)?.touched?.includes(field) &&
    (apiRef.current.getRow(id)?.errors?.includes(field) ||
      value === "" ||
      value === null);

  return (
    <TextField
      data-testid={props.dataTestId}
      value={value !== null ? value : ""}
      disabled={props.readOnly}
      error={error}
      sx={{
        height: "100%",
        "& .MuiInputBase-root": {
          height: "100%",
          borderRadius: "0px",
        },
        "& fieldset": { border: "none" },
        "& input": {
          paddingLeft: "8px",
          fontSize: theme.datagrid.cell.fontSize,
        },
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: theme.palette.text.primary,
        },
      }}
      onBlur={() => {
        const row = apiRef.current.getRow(id);
        apiRef.current.setEditCellValue({
          id,
          field,
          value: value,
        });
        apiRef.current.updateRows([
          {
            id,
            touched: row?.touched
              ? [...new Set([...row.touched, field])]
              : [field],
          },
        ]);
      }}
      onChange={(event) => {
        if (event.target.value === "" || isNumeric(event.target.value)) {
          const value =
            numberType === "integer"
              ? parseInt(event.target.value) || ""
              : event.target.value;
          apiRef.current.setEditCellValue({
            id,
            field,
            value: value,
          });
        }
      }}
      InputProps={{
        inputProps: {
          style: { textAlign: "left" },
        },
      }}
    />
  );
};
