import { combineReducers, configureStore } from "@reduxjs/toolkit";
import auth0Slice from "../features/auth/auth0Slice";
import settingsSlice from "../features/settingsSlice";
import { orderApi } from "../../providers/hop-ord-server/api";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { rtkQueryErrorLogger } from "./sentry";
import { createReduxEnhancer } from "@sentry/react";

// Create the root reducer independently to obtain the RootState type
const rootReducer = combineReducers({
  auth: auth0Slice,
  settings: settingsSlice,
  [orderApi.reducerPath]: orderApi.reducer,
});

const sentryReduxEnhancer = createReduxEnhancer({});

export const setupStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(orderApi.middleware, rtkQueryErrorLogger),
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers().concat(sentryReduxEnhancer);
    },
  });

export const store = setupStore();

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore["dispatch"];
