import { ProtocolInfoOut } from "@providers/hop-ord-server/api";
import fuzzysort from "fuzzysort";

const SIMILAR_THRESHOLD = 0.5;
const NO_MATCH_THRESHOLD = 0.2;

interface FuzzySortResult {
  obj: ProtocolInfoOut;
  target: string;
  score: number;
}

export interface ProtocolResult {
  item: ProtocolInfoOut;
  score: number;
  status: string;
}

interface ProtocolSearchResults {
  hasExactMatches: boolean;
  hasSimilarMatches: boolean;
  results: ProtocolResult[];
}

export const protocolSearch = (
  searchText: string,
  filteredProtocols: ProtocolInfoOut[],
): ProtocolSearchResults => {
  const fuzzySortOptions = {
    threshold: NO_MATCH_THRESHOLD,
    key: "name",
    all: true, // return all results for an empty search
  };

  const results = fuzzysort.go(searchText, filteredProtocols, fuzzySortOptions);

  const exactMatches = results.filter(
    (result: FuzzySortResult) => Number(result.score) > SIMILAR_THRESHOLD,
  );
  const similarMatches = results.filter(
    (result: FuzzySortResult) => Number(result.score) <= SIMILAR_THRESHOLD,
  );

  return {
    hasExactMatches: exactMatches.length > 0 || !searchText,
    hasSimilarMatches: exactMatches.length === 0 && similarMatches.length > 0,
    results: results.map((result: FuzzySortResult) => {
      return {
        item: result.obj,
        score: Number(result.score),
        status:
          Number(result.score) <= 1 && Number(result.score) > SIMILAR_THRESHOLD
            ? "EXACT"
            : Number(result.score) > NO_MATCH_THRESHOLD &&
                Number(result.score) <= SIMILAR_THRESHOLD
              ? "SIMILAR"
              : "N/A",
      };
    }),
  };
};
