import {
  FormContainer,
  TextFieldElement,
  SwitchElement,
} from "react-hook-form-mui";
import { useForm } from "react-hook-form";
import { Box, Card, Stack, Typography } from "@mui/material";
import {
  SiteGroupIn,
  SiteGroupOut,
  useWebApiV1UpdateSiteGroupMutation,
} from "@providers/hop-ord-server/api";
import { useCallback, useEffect, useMemo } from "react";
import { debounce } from "lodash";

interface InputTypes {
  active: boolean;
  frequency: string;
  location: string;
  thickness: string;
}

interface Props {
  isReadOnly: boolean;
  siteGroup: SiteGroupOut;
  setValidationTrigger: (fn: () => Promise<boolean>) => void;
}

const BolusFields = ({
  isReadOnly,
  siteGroup,
  setValidationTrigger,
}: Props) => {
  const form = useForm<InputTypes>({
    defaultValues: {
      active: siteGroup.bolusActive ?? false,
      frequency: siteGroup.bolusFrequency ?? "",
      location: siteGroup.bolusLocation ?? "",
      thickness: siteGroup.bolusThickness ?? "",
    },
    mode: "all",
  });

  useEffect(() => {
    setValidationTrigger(() => form.trigger());
  }, [form, setValidationTrigger]);

  const [updateSiteGroupMutation] = useWebApiV1UpdateSiteGroupMutation();

  const debouncedSave = useMemo(
    () =>
      debounce((values: SiteGroupIn) => {
        updateSiteGroupMutation({
          siteGroupId: siteGroup.id,
          siteGroupIn: values,
        });
      }, 1000),
    [siteGroup.id, updateSiteGroupMutation]
  );

  const handleSave = useCallback(
    (values: SiteGroupIn) => {
      debouncedSave(values);
    },
    [debouncedSave]
  );

  useEffect(() => {
    const subscription = form.watch((values) => {
      !isReadOnly &&
        handleSave({
          bolusActive: values.active ?? false,
          bolusFrequency: values.frequency ?? "",
          bolusLocation: values.location ?? "",
          bolusThickness: values.thickness ?? "",
        });
    });

    // clean up on unmount
    return () => subscription.unsubscribe();
  }, [handleSave, isReadOnly, form]);

  const activeWatch = form.watch("active", siteGroup.bolusActive);
  useEffect(() => {
    if (!activeWatch) {
      form.reset({ active: false });
    }
  }, [activeWatch, form]);

  if (isReadOnly && activeWatch === false) {
    return null;
  }

  return (
    <Card style={{ marginLeft: 2, marginRight: 2 }}>
      <FormContainer<InputTypes>
        formContext={form}
        onError={() => console.log()}
        onSuccess={() => console.log()}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="subtitle1">Bolus Required</Typography>
          <SwitchElement
            name="active"
            data-testid="bolus-active"
            disabled={isReadOnly}
            label=""
            labelPlacement="top"
            sx={{ padding: 0, margin: 0 }}
          />
        </Stack>
        {activeWatch && (
          <Stack gap={2} p={2}>
            <Stack
              direction={isReadOnly ? "column" : { sm: "column", md: "row" }}
              gap={2}
            >
              <TextFieldElement
                fullWidth
                label="Frequency"
                name="frequency"
                required
                disabled={isReadOnly}
                placeholder="e.g. Daily"
                data-testid="bolus-frequency"
              />
              <TextFieldElement
                fullWidth
                label="Location"
                name="location"
                disabled={isReadOnly}
                placeholder="e.g. Entire field"
                data-testid="bolus-location"
              />
            </Stack>
            <Stack
              direction={isReadOnly ? "column" : { sm: "column", md: "row" }}
              gap={2}
            >
              <TextFieldElement
                sx={{ flex: 1 }}
                label="Thickness"
                name="thickness"
                disabled={isReadOnly}
                placeholder="e.g. 8mm"
                data-testid="bolus-thickness"
              />
              <Box flex={1} />
            </Stack>
          </Stack>
        )}
      </FormContainer>
    </Card>
  );
};

export default BolusFields;
