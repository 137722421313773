import { Divider, Stack, Skeleton } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  text: string;
  actions: ReactNode;
  isLoading: boolean;
}

const ActionBar = ({ text, actions, isLoading }: Props) => {
  if (isLoading) {
    return (
      <>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          data-testid="loading-action-bar"
        >
          <Skeleton variant="text" width={160} />
          <Stack direction="row" gap={1}>
            <Skeleton variant="rounded" width={125} height={40} />
            <Skeleton variant="rounded" width={90} height={40} />
          </Stack>
        </Stack>
        <Divider />
      </>
    );
  }
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom={1}
      >
        Action: {text}
        {actions}
      </Stack>
      <Divider />
    </>
  );
};

export default ActionBar;
