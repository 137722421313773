import { useEffect } from "react";
import { autoScroll } from "./common";
import { getFormValidationErrors } from "./common";

interface AutoScrollProps {
  submitCount: number;
}

export default function AutoScroll(props: AutoScrollProps) {
  const elements = getFormValidationErrors();
  useEffect(() => {
    autoScroll(elements, props.submitCount > 0);
  }, [props.submitCount, elements]);

  return <></>;
}
