import { Avatar, Grid, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import AccessTime from "@mui/icons-material/AccessTime";
import { getAvatarText, timeFromNow } from "@utils";
import {
  CommentOut,
  CommentAttachmentOut,
} from "@providers/hop-ord-server/api";
import { theme } from "@theme";
import ImageBox from "@components/ImageBox/ImageBox";

const Comment = ({
  createdBy,
  id,
  createdAt,
  attachments,
  content,
}: CommentOut) => {
  const initials = getAvatarText(createdBy);

  return (
    <Stack
      style={{
        border: "1px solid",
        borderColor: theme.palette.borderColor.main,
        borderRadius: "4px",
      }}
    >
      <Stack direction="row" padding={1} gap={1}>
        <Avatar>{initials}</Avatar>
        <Stack width="100%">
          <Stack direction="row" justifyContent="space-between" flex={1}>
            <Typography
              data-testid={`${id}-comment-author`}
              align="left"
              variant="body1"
            >
              {createdBy}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              color={theme.palette.text.secondary}
            >
              <AccessTime fontSize="small" />
              <Typography
                data-testid={`${id}-comment-createdAt`}
                align="left"
                variant="body2"
              >
                {createdAt && timeFromNow(createdAt)}
              </Typography>
            </Stack>
          </Stack>
          <Typography
            style={{ whiteSpace: "pre-line" }}
            data-testid={`${id}-comment-content`}
            variant="body2"
          >
            {content}
          </Typography>
        </Stack>
      </Stack>

      {attachments.length > 0 && (
        <Grid container spacing={0.5} padding={1}>
          {attachments.map((attachment: CommentAttachmentOut, i: number) => {
            return (
              <Grid item key={`image-url-${i}`} xs={6}>
                <ImageBox
                  testId={`${i}-comment-image`}
                  key={`${i}-image-path-key`}
                  fileName={attachment.fileName}
                  url={attachment.url}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Stack>
  );
};

export default Comment;
