import { HorizontalLogo } from "@components/Logo/Logo";
import { Container, Typography } from "@mui/material";
import { theme } from "@theme";
import background from "@assets/mobile-background.png?url";
import { isAndroid } from "react-device-detect";
import { Button } from "@components";

const MobilePage = () => {
  return (
    <Container
      style={{
        backgroundImage: `url(${background})`,
        backgroundColor: theme.palette.primary.main,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100dvh",
      }}
    >
      <div style={{ flexGrow: 1 }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "32px",
          alignItems: "center",
          padding: 32,
          paddingBottom: 48,
        }}
      >
        <HorizontalLogo color="white" width={254} />
        <Typography
          data-testid="mobile-intro"
          variant="body1"
          color="white"
          sx={{ textAlign: "center" }}
        >
          For the best experience, we recommend using our native app on your
          mobile device
        </Typography>
        {!isAndroid && (
          <Button
            data-testid="download-ios"
            variant="contained"
            color="secondary"
            href="https://apps.apple.com/us/app/lumonus-planning/id6479221412"
            size="large"
          >
            Download iOS APP
          </Button>
        )}
        <Typography
          data-testid="mobile-end-text"
          variant="body1"
          color="white"
          sx={{ textAlign: "center" }}
        >
          To create your first order, log into Lumonus Planning via desktop.{" "}
        </Typography>
      </div>
    </Container>
  );
};

export default MobilePage;
