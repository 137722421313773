import { UploadPayload } from "@providers/hop-ord-server/api";
import axios from "axios";

export const uploadToS3 = async (
  payload: UploadPayload,
  file: File | null,
  setProgress?: (percentage: number) => void,
  onComplete?: () => void,
  onError?: () => void
) => {
  const formData = new FormData();

  // AWS Fields for uploading
  formData.append("key", payload.fields.key);
  formData.append("policy", payload.fields.policy);
  payload.fields.signature &&
    formData.append("signature", payload.fields.signature);
  formData.append(
    "x-amz-security-token",
    payload.fields["x-amz-security-token"]
  );
  payload.fields.AWSAccessKeyId &&
    formData.append("AWSAccessKeyId", payload.fields.AWSAccessKeyId);
  payload.fields["x-amz-credential"] &&
    formData.append("x-amz-credential", payload.fields["x-amz-credential"]);
  payload.fields["x-amz-signature"] &&
    formData.append("x-amz-signature", payload.fields["x-amz-signature"]);
  payload.fields["x-amz-algorithm"] &&
    formData.append("x-amz-algorithm", payload.fields["x-amz-algorithm"]);
  payload.fields["x-amz-date"] &&
    formData.append("x-amz-date", payload.fields["x-amz-date"]);

  formData.append("file", file || "");
  await axios
    .post(payload.url, formData, {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentage = total ? Math.floor((loaded * 100) / total) : 0;
        setProgress && setProgress(percentage);
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(() => {
      onComplete && onComplete();
    })
    .catch(() => {
      onError && onError();
    });
  return formData;
};
