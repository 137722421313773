import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowParams,
} from "@mui/x-data-grid";

import { DataTable, DataTableRefObject } from "@components/DataTable/DataTable";
import RemoveCircle from "@mui/icons-material/RemoveCircle";

import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Ref, useCallback, useEffect, useImperativeHandle } from "react";
import { GridRowModel } from "@mui/x-data-grid";
import { OarsOut } from "@providers/hop-ord-server/api";

export interface OarInfo extends OarsOut {
  errors?: string[];
  touched?: string[];
}
interface OarTableProps {
  isReadOnly: boolean;
  updateOar: (row: GridRowModel) => boolean;
  removeOar: (id: string | number) => void;
  addOar: () => void;
  oarData: OarInfo[];
  organOptions: string[];
  submitCount?: number;
  reportValidity: (isValid: boolean) => void;
  dataTestId: string;
}
import { CustomCellAutoComplete } from "@components/DataTable/CustomCellAutocomplete";
import { CustomCellTextField } from "@components/DataTable/CustomCellTextField";
import React from "react";

export interface OarTableRefObject {
  trigger: () => boolean;
}

const OarTable = React.forwardRef(
  (props: OarTableProps, ref: Ref<OarTableRefObject>) => {
    const tableRef = React.useRef<DataTableRefObject>(null);
    const {
      reportValidity,
      oarData,
      removeOar,
      addOar,
      updateOar,
      organOptions,
      dataTestId,
      isReadOnly,
    } = props;
    const isEditable = !isReadOnly;

    const addOarWrapper = () => {
      const anyEmpty = oarData.some((oar) => oar.organ === "");
      if (anyEmpty) {
        return;
      }
      addOar();
    };

    const validateOars = useCallback(
      (oarData: OarInfo[]) => {
        // check that all organ fields are filled
        const valid = oarData.every((oar) => oar["organ"] !== "");
        reportValidity(valid);
      },
      [reportValidity]
    );

    useEffect(() => {
      // TODO: Temporary site validation function until we can ingrain things into the datatable better
      validateOars(oarData);
    }, [validateOars, oarData]);

    const columns: GridColDef[] = [
      {
        field: "organ",
        headerName: "Organ",
        editable: isEditable,
        flex: 1,
        sortable: false,
        type: "singleSelect",
        valueOptions: organOptions,
        renderEditCell: (params: GridRenderEditCellParams) => (
          <CustomCellAutoComplete dataTestId="organ-edit" {...params} />
        ),
        renderCell: (params: GridRenderCellParams) => (
          <CustomCellAutoComplete
            dataTestId="organ-view"
            {...params}
            placeholder="Select organ"
            readOnly={!isEditable}
          />
        ),
      },
      {
        field: "constraints",
        headerName: "Constraints",
        editable: isEditable,
        flex: 1,
        sortable: false,
        type: "string",
        renderCell: (params: GridRenderEditCellParams) => (
          <CustomCellTextField
            multiline={true}
            {...params}
            readOnly={
              !isEditable || params.api.getCellValue(params.id, "organ") === ""
            }
          />
        ),
        renderEditCell: (params: GridRenderEditCellParams) => (
          <CustomCellTextField
            multiline={true}
            {...params}
            readOnly={
              !isEditable || params.api.getCellValue(params.id, "organ") === ""
            }
          />
        ),
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.1,
        getActions: (params: GridRowParams) =>
          isEditable
            ? [
                <GridActionsCellItem
                  {...params}
                  icon={<RemoveCircle />}
                  onClick={() => isEditable && removeOar(params.id)}
                  label="Delete"
                  id="delete"
                />,
              ]
            : [],
      },
    ];

    const hasErrors = oarData.some((oar) => {
      if (oar.errors === undefined) {
        return false;
      }
      return oar.errors.some((err) => oar.touched?.includes(err));
    });
    useImperativeHandle(ref, () => ({
      trigger: (): boolean => {
        return tableRef.current?.trigger() || false;
      },
    }));
    return (
      <Card
        data-testid={dataTestId}
        style={{ marginLeft: "2px", marginRight: "2px" }}
      >
        <CardHeader
          title="Organs At Risk"
          titleTypographyProps={{ variant: "subtitle1" }}
        />
        {hasErrors && <Alert severity="error">Missing required fields</Alert>}
        <CardContent
          style={{
            paddingTop: 0,
          }}
        >
          <Stack spacing={1}>
            <DataTable
              ref={tableRef}
              columns={columns}
              rows={oarData}
              updateRow={updateOar}
              emptyRowComment="Organs will be displayed here once added"
            />
            {isEditable && (
              <Stack direction="row" justifyContent="start" paddingTop={1}>
                <Button
                  onClick={addOarWrapper}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  data-testid="add-oar"
                >
                  Add organ
                </Button>
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>
    );
  }
);

export default OarTable;
