import { Stack, Typography } from "@mui/material";
import { PLAN_DELIVERY_OPTIONS } from "@components/PatientDetailsForm/constants";

interface Props {
  planDelivery: string;
  treatingDepartment: string;
  submittedDatetime: string;
}

interface OrderDetailsRowProps {
  label: string;
  value: string;
}

interface Option {
  id: string;
  label: string;
}

const getDisplayOption = (value: string, options: Option[]): string => {
  const mappedValue = options.find(
    (option: Option) => option?.id === value
  )?.label;
  return mappedValue || value;
};

export const OrderDetailsRow = ({ label, value }: OrderDetailsRowProps) => (
  <div style={{ display: "flex" }}>
    <Typography variant="subtitle2" data-testid={`${label}-label`}>
      {label}
    </Typography>
    <Typography
      variant="body2"
      data-testid={`${label}-value`}
      style={{ marginLeft: 8 }}
    >
      {value}
    </Typography>
  </div>
);

const OrderDetails = ({
  planDelivery,
  treatingDepartment,
  submittedDatetime,
}: Props) => {
  return (
    <Stack>
      <OrderDetailsRow
        label="Plan delivery:"
        value={getDisplayOption(planDelivery, PLAN_DELIVERY_OPTIONS)}
      />
      <OrderDetailsRow
        label="Treating Location/Unit:"
        value={treatingDepartment}
      />
      <OrderDetailsRow label="Submitted:" value={submittedDatetime} />
    </Stack>
  );
};

export default OrderDetails;
