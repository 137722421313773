import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { theme } from "@theme";
import React from "react";
import { ScorecardObject } from "@providers/hop-ord-server/api";

interface ScorecardProps {
  data: ScorecardObject;
}

const outcomeTranslation: { [key: string]: string } = {
  Passed: "Pass",
  Warning: "Warning",
  Failed: "Warning",
};

const getOutcomeColor = (outcome: string) => {
  switch (outcome) {
    case "Passed":
      return theme.palette.success.main;
    case "Failed":
      return theme.palette.warning.main;
    default:
      return theme.palette.warning.main;
  }
};

const Scorecard = ({ data }: ScorecardProps) => {
  return (
    <Stack spacing={1}>
      <TableContainer
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{ th: { fontWeight: theme.typography.fontWeightBold } }}
            >
              <TableCell width="25%">Structure</TableCell>
              <TableCell align="left" width="25%">
                Outcome
              </TableCell>
              <TableCell align="left" width="25%">
                Actual Value
              </TableCell>
              <TableCell align="left" width="25%">
                Dosimetric Criteria
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.DVHStatistics.map(
              ({ Structure, DosimetricCriteria }, dvhStatisticIndex) => (
                <React.Fragment key={dvhStatisticIndex}>
                  {DosimetricCriteria.map(
                    (
                      { Outcome, Actual, Criterion },
                      dosimetricCriteriaIndex
                    ) => (
                      <TableRow
                        sx={{
                          td: {
                            border:
                              dosimetricCriteriaIndex !==
                              DosimetricCriteria.length - 1
                                ? 0
                                : undefined,
                          },
                        }}
                        key={`${Structure}_${dosimetricCriteriaIndex}_${Criterion}`}
                        data-testid={`scorecard-row-${dvhStatisticIndex}-${dosimetricCriteriaIndex}`}
                      >
                        <TableCell width="25%" data-testid="structure">
                          <Typography variant="body2">
                            {dosimetricCriteriaIndex === 0
                              ? Structure
                              : undefined}
                          </Typography>
                        </TableCell>
                        <TableCell
                          style={{ width: "25%" }}
                          data-testid="outcome"
                        >
                          <Typography
                            variant="body2"
                            alignItems="center"
                            width="fit-content"
                            paddingX={1}
                            paddingY={0.5}
                            borderRadius={16}
                            sx={{ backgroundColor: getOutcomeColor(Outcome) }}
                          >
                            {outcomeTranslation[Outcome]}
                          </Typography>
                        </TableCell>
                        <TableCell width="25%" data-testid="actual">
                          <Typography variant="body2">{Actual}</Typography>
                        </TableCell>
                        <TableCell width="25%" data-testid="criterion">
                          <Typography variant="body2">{Criterion}</Typography>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </React.Fragment>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default Scorecard;
