import { SvgIcon } from "@mui/material";

const ScorecardFileIcon = ({ color }: { color: string }): React.ReactNode => {
  return (
    <SvgIcon>
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.6 0L16 6.66667V17.7778C16 19 15.04 20 13.8667 20H2.12267C0.949333 20 0 19 0 17.7778L0.0106669 2.22222C0.0106669 1 0.96 0 2.13333 0H9.6ZM8.53333 7.77778H14.4L8.53333 1.66667V7.77778ZM2 10H8V12H2V10ZM11 10H13V12H11V10ZM10 13V9H14V13H10ZM13 15H11V17H13V15ZM10 14V18H14V14H10ZM8 15H2V17H8V15Z"
          fillOpacity="1"
        />
      </svg>
    </SvgIcon>
  );
};

export default ScorecardFileIcon;
