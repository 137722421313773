import React, { useState } from "react";
import { Stack, TextField, Divider } from "@mui/material";

interface AdditionalInfoProps {
  isReadOnly: boolean;
  currentValue: string;
  onBlur: (field: string, value: string) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const AdditionalInfo = (props: AdditionalInfoProps) => {
  const { isReadOnly, onBlur, currentValue, inputRef } = props;
  const [text, setText] = useState<string>(currentValue);

  return (
    <Stack>
      <Divider />
      <TextField
        id="additional-info"
        label="Additional order information"
        variant="outlined"
        multiline
        margin="normal"
        value={text}
        maxRows={4}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setText(event.target.value);
        }}
        onBlur={() => {
          onBlur("additionalInfo", text);
        }}
        disabled={isReadOnly}
        inputRef={inputRef}
        sx={{ marginTop: 3, marginBottom: 10 }}
      />
    </Stack>
  );
};

export default AdditionalInfo;
