import { SiteGroupPanel } from "@components";
import {
  Box,
  Stack,
  useMediaQuery,
  Badge,
  Tab,
  Tabs,
  Skeleton,
} from "@mui/material";
import PortaledRightDrawer from "@pages/Layout/PortaledRightDrawer";
import { theme } from "@theme";
import AdditionalInfo from "@pages/Order/components/AdditionalInfo/AdditionalInfo";
import OrderDetails from "@pages/Order/components/OrderDetails/OrderDetails";
import { OrderOut, SiteGroupOut } from "@providers/hop-ord-server/api";
import { useCommentsApiV1GetUnreadCommentCountQuery } from "@providers/hop-ord-server/api";
import { Comments } from "@components";
import { useState } from "react";
import { toLocaleDateTimeString } from "@utils";

export type PanelType = "comments" | "details";
export const DEFAULT_DRAWER = "details";

interface Props {
  selectedPanel: PanelType;
  setSelectedPanel: (value: any) => void;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  orderData?: OrderOut;
  siteGroupData: SiteGroupOut[];
}

const OrderTrackerDrawer = ({
  setOpen: setDrawerOpen,
  open: drawerOpen,
  setSelectedPanel,
  selectedPanel,
  orderData,
  siteGroupData,
}: Props) => {
  const screenIsLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const [siteGroupPanelLoading, setSiteGroupPanelLoading] = useState(true);

  const { data: unreadCommentCount } =
    useCommentsApiV1GetUnreadCommentCountQuery(
      {
        orderGroupId: orderData?.orderGroupId as number,
      },
      { skip: !orderData?.orderGroupId },
    );

  const handleSiteGroupLoadingChange = (isLoading: boolean) => {
    setSiteGroupPanelLoading(isLoading);
  };
  return (
    <PortaledRightDrawer
      setOpen={setDrawerOpen}
      open={drawerOpen}
      permanent={screenIsLarge}
      drawerWidth={600}
    >
      <Stack data-testid="right-drawer" padding={3}>
        <Tabs
          onChange={(_event, value) => setSelectedPanel(value)}
          value={selectedPanel}
          style={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Tab
            data-testid="drawer-details-tab"
            label="Plan details"
            value="details"
          />
          <Tab
            data-testid="drawer-comments-tab"
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <span>Comments</span>
                {unreadCommentCount ? (
                  <Badge
                    badgeContent={unreadCommentCount}
                    color="error"
                    sx={{
                      ".MuiBadge-badge": {
                        transform: "none",
                        position: "relative",
                      },
                    }}
                  />
                ) : null}
              </Stack>
            }
            value="comments"
          />
        </Tabs>
        {selectedPanel === "details" && (
          <Stack
            data-testid="drawer-details"
            overflow="auto"
            height={1}
            width={1}
          >
            <Box marginTop={2} marginBottom={2}>
              {siteGroupPanelLoading ? (
                <Stack>
                  <Skeleton variant="rounded" height={56} />
                </Stack>
              ) : (
                <OrderDetails
                  planDelivery={orderData?.planDelivery || "-"}
                  treatingDepartment={orderData?.treatingDepartment || "-"}
                  submittedDatetime={
                    orderData?.submittedDatetime
                      ? toLocaleDateTimeString(orderData?.submittedDatetime)
                      : "-"
                  }
                />
              )}
            </Box>
            {siteGroupData?.map((siteGroup, index) => (
              <SiteGroupPanel
                index={index}
                key={siteGroup.id}
                siteGroup={siteGroup}
                isReadOnly={true}
                submitCount={0}
                reportValidity={() => null}
                onDeleteCallback={() => null}
                onLoadingChange={handleSiteGroupLoadingChange}
                showOrderName={false}
                doseUnit={orderData?.doseUnit ?? ""}
                fractionUnit={orderData?.fractionUnit ?? ""}
              />
            ))}
            <Box paddingX={2}>
              {siteGroupPanelLoading ? (
                <Stack>
                  <Skeleton variant="rounded" height={56} />
                </Stack>
              ) : (
                <AdditionalInfo
                  isReadOnly
                  currentValue={orderData?.additionalInfo || ""}
                  onBlur={() => null}
                />
              )}
            </Box>
          </Stack>
        )}
        {selectedPanel === "comments" && orderData && (
          <Comments
            orderGroupId={orderData.orderGroupId}
            orderId={orderData.id}
          />
        )}
      </Stack>
    </PortaledRightDrawer>
  );
};

export default OrderTrackerDrawer;
