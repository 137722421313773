import { ScorecardObject } from "@providers/hop-ord-server/api";

export interface ScorecardSummary {
  passed: number;
  major: number;
  minor: number;
}

type OutcomeCounts = { [key: string]: number };

export const getScorecardSummary = (scorecard: ScorecardObject): string => {
  // Get the summary of passed, major or minor variations
  const outcomeCounts: OutcomeCounts = {
    Passed: 0,
    Failed: 0,
    Warning: 0,
  };

  scorecard.DVHStatistics.forEach((item) => {
    item.DosimetricCriteria.forEach((criteria) => {
      const outcome = criteria.Outcome;
      outcomeCounts[outcome]++;
    });
  });

  const totalWarnings = outcomeCounts.Failed + outcomeCounts.Warning;

  return `${totalWarnings} Warning${totalWarnings > 1 ? "s" : ""}`;
};
