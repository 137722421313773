export const MAX_FILE_SIZE = 5242880; // 5 MB
export const ACCEPTED_FILE_TYPES = {
  "application/json": [".json"],
};
export const DRAG_AND_DROP = "Click to upload or drag and drop";
export const APPROVED_FILES = "JSON (max. 5MB)";
export const FILE_DATA_INVALID = "file-data-invalid";

export const UPLOAD_ERRORS = {
  "file-invalid-type": "File type is not approved",
  "file-too-large": "File is too large (maximum supported file size is 5 MB)",
  [FILE_DATA_INVALID]: "File has missing or invalid fields",
  other: "Upload failed. Please delete and try again",
};
