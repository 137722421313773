export const ONE_DAY = 24 * 60 * 60 * 1000;

export const statusMessages = [
  "1. Adding couch",
  "2. Adding beams",
  "3. Setting beam names",
  "4. Adding prescription",
  "5. Adding optimisation objectives",
  "6. Optimising",
  "7. Calculation",
  "8. Setting clinical goals",
  "9. Normalising",
  "10 Saving and existing",
];
export interface CCOrder {
  id: number;
  patientName: string;
  patientDob: string;
  patientMrn: string;
  planDelivery: string;
  patientGender: string;
  clinic: string;
  treatmentStart: Date | null; // Allowing both Date and null
  simulationStatus: string;
  simulationCompleteDate: Date | null;
  contouringStatus: string;
  contouringCompleteDate: Date | null;
  targetStatus: string;
  targetCompleteDate: Date | null;
  reviewStatus: string;
  reviewCompleteDate: Date | null;
  planStatus: {
    status: string;
    name: string | null;
    planType: string | null;
    statusMessage: string | null;
    progress: number;
    step: number;
  };
}

export const mockOrders: CCOrder[] = [
  {
    id: -1,
    patientName: "Grace Walker",
    patientDob: "Jan-20-1957",
    patientGender: "(F)",
    patientMrn: "182246",
    clinic: "Lakeside Medical Clinic",
    treatmentStart: new Date("2024-09-27T17:30:00"),
    planDelivery: "Emergency",
    simulationStatus: "complete",
    simulationCompleteDate: new Date("2024-09-27"),
    contouringStatus: "complete",
    contouringCompleteDate: new Date("2024-09-27"),
    targetStatus: "complete",
    targetCompleteDate: new Date("2024-09-27"),
    reviewStatus: "complete",
    reviewCompleteDate: new Date("2024-09-28"),
    planStatus: {
      status: "complete",
      name: "Pelvis VMAT 2000/5",
      planType: "Automated Plan",
      statusMessage: "Sep-27-2024, 5:30 PM",
      progress: 100,
      step: 10,
    },
  },
  {
    id: -1,
    patientName: "Olivia Taylor",
    patientDob: "Sep-01-1955",
    patientGender: "(F)",
    patientMrn: "181452",
    clinic: "Lakeside Medical Clinic",
    treatmentStart: new Date("2024-09-26T17:30:00"),
    planDelivery: "Urgent",
    simulationStatus: "complete",
    simulationCompleteDate: new Date("2024-09-26"),
    contouringStatus: "complete",
    contouringCompleteDate: new Date("2024-09-26"),
    targetStatus: "complete",
    targetCompleteDate: new Date("2024-09-26"),
    planStatus: {
      status: "complete",
      name: "Breast+SCF+Ax VMAT 5000/25 + Boost e1000/5 (Invasive)",
      planType: "Manual Plan",
      statusMessage: `Completed: ${new Date("2024-09-27T13:45:00").toLocaleString()}`,
      progress: 100,
      step: 10,
    },
    reviewStatus: "rejected",
    reviewCompleteDate: null,
  },
  {
    id: -1,
    patientName: "Adam McAdams",
    patientGender: "(M)",
    patientDob: "Mar-25-1987",
    patientMrn: "183922",
    clinic: "Lakeside Medical Clinic",
    treatmentStart: new Date(Date.now() + ONE_DAY * 4),
    planDelivery: "Standard",
    simulationStatus: "complete",
    simulationCompleteDate: new Date(Date.now() - ONE_DAY * 4),
    contouringStatus: "complete",
    contouringCompleteDate: new Date(Date.now() - ONE_DAY * 3),
    targetStatus: "complete",
    targetCompleteDate: new Date(Date.now() - ONE_DAY * 2),
    planStatus: {
      status: "in-progress",
      name: "Brain SRS 1400/1",
      planType: "Automated Plan",
      statusMessage: statusMessages[2],
      progress: 20,
      step: 2,
    },
    reviewStatus: "not-started",
    reviewCompleteDate: null,
  },
  {
    id: -1,
    patientName: "Emma Anderson",
    patientGender: "(F)",
    patientDob: "Apr-12-1952",
    patientMrn: "182630",
    clinic: "Lakeside Medical Clinic",
    treatmentStart: new Date(Date.now() + ONE_DAY * 4),
    planDelivery: "Standard",
    simulationStatus: "complete",
    simulationCompleteDate: new Date(Date.now() - ONE_DAY * 4),
    contouringStatus: "complete",
    contouringCompleteDate: new Date(Date.now() - ONE_DAY * 3),
    targetStatus: "complete",
    targetCompleteDate: new Date(Date.now() - ONE_DAY * 2),
    planStatus: {
      status: "in-progress",
      name: "LT Tonsil VMAT 6600-5400/30",
      planType: "Automated Plan",
      statusMessage: statusMessages[0],
      progress: 0,
      step: 0,
    },
    reviewStatus: "not-started",
    reviewCompleteDate: null,
  },
  {
    id: -1,
    patientName: "Noah Brown",
    patientGender: "(M)",
    patientMrn: "182345",
    patientDob: "Apr-12-1952",
    clinic: "Lakeside Medical Clinic",
    planDelivery: "Standard",
    treatmentStart: new Date(Date.now() + ONE_DAY * 4),
    simulationStatus: "complete",
    simulationCompleteDate: new Date(),
    contouringStatus: "pending",
    contouringCompleteDate: null,
    targetStatus: "not-started",
    targetCompleteDate: null,
    reviewStatus: "not-started",
    reviewCompleteDate: null,
    planStatus: {
      status: "not-started",
      name: null,
      planType: null,
      statusMessage: null,
      progress: 0,
      step: 0,
    },
  },
  {
    id: -1,
    patientName: "Sofia Taylor",
    patientGender: "(F)",
    patientMrn: "183491",
    patientDob: "Apr-12-1952",
    clinic: "Lakeside Medical Clinic",
    planDelivery: "Standard",
    treatmentStart: null,
    simulationStatus: "pending",
    simulationCompleteDate: null,
    contouringStatus: "not-started",
    contouringCompleteDate: null,
    targetStatus: "not-started",
    targetCompleteDate: null,
    reviewStatus: "not-started",
    reviewCompleteDate: null,
    planStatus: {
      status: "not-started",
      name: null,
      planType: null,
      statusMessage: null,
      progress: 0,
      step: 0,
    },
  },
];
