import { useEffect } from "react";
import { Paper, Skeleton, Stack } from "@mui/material";
import { Comment, SendComment } from "./CommentsComponents";
import {
  CommentOut,
  useCommentsApiV1GetCommentForOrderQuery,
  useCommentsApiV1PostCommentMutation,
  useCommentsApiV1SetCommentsReadMutation,
  useNotificationsApiV1UpdateNotificationsMutation,
  useWebApiV1OrderQuery,
} from "@providers/hop-ord-server/api";
import { CustomTheme, useTheme } from "@mui/material";

interface Props {
  orderGroupId: number;
  orderId: number;
}

const Comments = (props: Props) => {
  const { orderId, orderGroupId } = props;
  const theme = useTheme<CustomTheme>();

  const { data } = useCommentsApiV1GetCommentForOrderQuery({ orderGroupId });

  const { data: patientData } = useWebApiV1OrderQuery({ orderId });
  const [postComment] = useCommentsApiV1PostCommentMutation();
  const [setCommentsRead] = useCommentsApiV1SetCommentsReadMutation();
  const [updateNotifications] =
    useNotificationsApiV1UpdateNotificationsMutation();

  // Set comments to read and update the comments notifications
  useEffect(() => {
    if (orderGroupId) {
      setCommentsRead({ orderGroupId });
      updateNotifications({
        orderGroupId,
        notificationIn: { notificationType: "NEW_COMMENT" },
      });
    }
  }, []);

  const sendComment = (content: string, fileIDs: number[]) => {
    if (!orderGroupId) return;

    postComment({
      orderGroupId,
      commentIn: {
        content,
        orderId: Number(orderId),
        attachmentIds: fileIDs,
      },
    });
  };

  const showSkeleton = !data || !patientData;
  const comments = [...(data || [])].reverse();

  if (!data || !orderGroupId) return null;

  return (
    <Paper elevation={0} data-testid="comment-paper">
      <SendComment
        orderGroupId={orderGroupId}
        sendComment={sendComment}
        isLoading={showSkeleton}
      />
      <Stack padding={1} spacing={1}>
        {showSkeleton && (
          <Stack flex={1} gap={1} data-testid="loading-comment-chain">
            <Skeleton variant="rounded" height={75} />
            <Skeleton
              variant="rounded"
              height={75}
              sx={{ bgcolor: theme.palette.skeleton.levelOne }}
            />
          </Stack>
        )}
        {comments.map((comment: CommentOut) => (
          <Comment key={`comment-${comment?.id}`} {...comment} />
        ))}
      </Stack>
    </Paper>
  );
};

export default Comments;
