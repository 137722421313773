import {
  Button,
  Stack,
  Divider,
  Skeleton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableFooter,
  Typography,
} from "@mui/material";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import theme from "../../../../theme/theme";
import { useState, useMemo, useEffect } from "react";
import { ProtocolResult } from "./protocolSearch";
import { ProtocolInfoOut } from "@providers/hop-ord-server/api";

interface ProtocolListProps {
  isLoading: boolean;
  protocols: ProtocolResult[];
  addProtocol: (protocol: ProtocolInfoOut) => void;
  isReadOnly: boolean;
  setShowAddSite: (open: boolean) => void;
  hasExactMatches: boolean;
  hasSimilarMatches: boolean;
}

interface NoResultsRowProps {
  hasSimilarMatches: boolean;
  setShowAddSite: (open: boolean) => void;
}

const ProtocolListSkeleton = () => (
  <Stack paddingX={1} paddingY={1}>
    <Skeleton width={100} variant="text" />
    <Divider sx={{ paddingY: 1 }} />
    {[...Array(5).keys()].map((n) => (
      <Stack key={n}>
        <Stack
          direction="row"
          height={50}
          justifyContent="space-between"
          alignItems="center"
        >
          <Skeleton width={400} variant="text" />
          <Skeleton width={70} height={40} />
        </Stack>
        <Divider />
      </Stack>
    ))}
    <Stack
      direction="row"
      paddingY={1}
      justifyContent="flex-end"
      alignItems="center"
      spacing={3}
    >
      <Skeleton width={70} height={30} />
      <Skeleton variant="circular" width={20} />
      <Skeleton variant="circular" width={20} />
    </Stack>
  </Stack>
);

const NoResultsRow = ({
  hasSimilarMatches,
  setShowAddSite,
}: NoResultsRowProps) => {
  return (
    <TableRow sx={{ height: 49 }} data-testid="no-results-found">
      <TableCell width="55%">
        <Stack>
          <Typography variant="body2" fontWeight="bold">
            No results found. Try clearing any applied filters to widen your
            search.
          </Typography>
          {hasSimilarMatches && (
            <Typography variant="body2" color={theme.palette.grey[600]}>
              Similar results.
            </Typography>
          )}
        </Stack>
      </TableCell>
      <TableCell width="45%" align="right">
        <Button
          onClick={() => {
            setShowAddSite(true);
          }}
          data-testid="manual-protocol-add-button"
          sx={{
            textTransform: "none",
            textDecoration: "underline",
            ":hover": {
              textTransform: "none",
              textDecoration: "underline",
            },
          }}
        >
          Can't find protocol? Add manual
        </Button>
      </TableCell>
    </TableRow>
  );
};

const ProtocolList = ({
  isLoading,
  protocols,
  addProtocol,
  isReadOnly,
  setShowAddSite,
  hasExactMatches,
  hasSimilarMatches,
}: ProtocolListProps) => {
  const [page, setPage] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(5);

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(0);
  }, [protocols]);

  useEffect(() => {
    // If we are showing the no results row, then only display 4 rows underneath
    // to maintain consistent table height
    setResultsPerPage(!hasExactMatches ? 4 : 5);
  }, [hasExactMatches, resultsPerPage]);

  const visibleRows = useMemo(
    () => protocols.slice(page * resultsPerPage, (1 + page) * resultsPerPage),
    [protocols, page, resultsPerPage]
  );

  // If less than 5 rows on page, fill table with empty rows so table remains consistent height
  const emptyRows = Math.max(0, (1 + page) * resultsPerPage - protocols.length);

  return isLoading ? (
    <Stack>
      <ProtocolListSkeleton />
    </Stack>
  ) : (
    <Stack>
      <TableContainer
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                th: {
                  fontSize: theme.typography.body1,
                  fontWeight: theme.typography.fontWeightBold,
                  paddingTop: "16px",
                  paddingBottom: "8px",
                },
              }}
            >
              <TableCell colSpan={2} data-testid="protocol-title">
                Protocols
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!hasExactMatches && (
              <NoResultsRow
                hasSimilarMatches={hasSimilarMatches}
                setShowAddSite={setShowAddSite}
              />
            )}
            {visibleRows.map((protocol: ProtocolResult, index: number) => (
              <TableRow
                key={protocol.item.id}
                sx={{ td: { fontSize: theme.typography.body1 } }}
                hover
                data-testid={`${index}-protocol-list-item`}
              >
                <TableCell width="80%" data-testid={`${index}-protocol-name`}>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography>{protocol.item.name}</Typography>
                  </Stack>
                </TableCell>
                <TableCell width="20%" align="right">
                  <Button
                    disabled={isReadOnly}
                    onClick={() => {
                      addProtocol(protocol.item);
                    }}
                    startIcon={<AddCircleIcon />}
                    data-testid={`${index}-protocol-add-button`}
                  >
                    Add
                  </Button>
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 49 * emptyRows,
                }}
              >
                <TableCell colSpan={2} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={protocols.length}
                rowsPerPage={resultsPerPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
                sx={{ borderBottomWidth: 0 }}
                data-testid="protocol-pagination"
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default ProtocolList;
