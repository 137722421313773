import Card from "@mui/material/Card";
import { Box, CardMedia } from "@mui/material";
import { supportEmail } from "../../../constants";

const ErrorPage = (): JSX.Element => {
  return (
    <Box
      style={{
        display: "flex",
        backgroundSize: "cover",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px",
        margin: "0px",
      }}
    >
      <Card style={{ padding: "2em", margin: "2em" }}>
        <CardMedia>
          {/* <img src={Horizon} /> */}
          <div data-testid="error-title">
            You do not have permission to view this page
          </div>
          <div id="error-detail">
            <span>
              If you believe you are seeing this screen in error, please contact
              Lumonus IT support at{" "}
            </span>
            &nbsp;
            <a href={supportEmail}>{supportEmail}</a>
          </div>
        </CardMedia>
      </Card>
    </Box>
  );
};

export default ErrorPage;
