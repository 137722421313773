import React, { useEffect } from "react";
import { CustomTextEditCellParams } from "./Interface";
import { DoseViewField } from "./DoseCellViewField";
import { IconButton, TextField } from "@mui/material";
import { isDoseArrayValid, isNumeric } from "@utils";
import { useGridApiContext } from "@mui/x-data-grid";
import theme from "../../theme/theme";
import AddIcon from "@mui/icons-material/Add";
import { DoseEditDialog } from "./DoseEditDialog";

export const DoseMenuEditField = (props: CustomTextEditCellParams) => {
  const { id, value, field, readOnly } = props;
  const [open, setOpen] = React.useState(false);
  const [doseValues, setDoseValues] = React.useState<string[]>([]);

  useEffect(() => {
    setDoseValues(value.doses);
    if (value.openDialog) {
      setDoseValues((prev) => [...prev, ""]);
    }
  }, []);

  useEffect(() => {
    if (doseValues.length > 1) {
      setOpen(true);
    }
  }, [doseValues]);

  useEffect(() => {
    if (open && doseValues.length === 1) {
      setDoseValues((prev) => [...prev, ""]);
    }
  }, [open]);

  const apiRef = useGridApiContext();

  const updateDoseValues = (doseArray: string[]) => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value: { doses: doseArray, openDialog: false },
    });
    setOpen(false);
  };

  return (
    <>
      {value.doses.length > 1 ? (
        <DoseViewField {...props} onClick={() => setOpen(true)} />
      ) : (
        <TextField
          data-testid="dose-edit-field"
          value={doseValues[0] || value.doses[0] || ""}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.stopPropagation();
            }
          }}
          placeholder=""
          label=""
          onChange={(event) => {
            if (!Array.isArray(value.doses)) return;
            const doseValue = event.target.value;
            if (event.target.value === "" || isNumeric(event.target.value)) {
              const newValues = [...doseValues];
              newValues[0] = doseValue;
              setDoseValues(newValues);
              apiRef.current.setEditCellValue({
                id,
                field,
                value: { doses: newValues, openDialog: false },
              });
            }
          }}
          onBlur={() => {
            const row = apiRef.current.getRow(id);
            apiRef.current.updateRows([
              {
                id,
                touched: row?.touched ? [...row.touched, field] : [field],
                errors: [
                  ...row.errors.filter((item: string) => item !== field),
                  ...(!isDoseArrayValid(doseValues) ? [field] : []),
                ],
              },
            ]);
          }}
          sx={{
            fontSize: theme.datagrid.cell.fontSize,
            height: "100%",
            "& .MuiInputBase-root": {
              padding: "0px!important",
              height: "100%",
              borderRadius: "0px",
            },
            "& fieldset": { border: "none" },
            "& input": {
              paddingLeft: "8px !important",
              paddingRight: "0px !important",
              fontSize: theme.datagrid.cell.fontSize,
            },
          }}
          InputProps={{
            autoFocus: !readOnly,
            endAdornment: (
              <IconButton
                data-testid="dose-edit-field-add-icon"
                onClick={() => {
                  setOpen(true);
                }}
                style={{ padding: "0px" }}
                size="large"
              >
                <AddIcon color="primary" />
              </IconButton>
            ),
          }}
        />
      )}
      <DoseEditDialog
        open={open}
        setOpen={setOpen}
        doseValues={doseValues}
        onSubmit={updateDoseValues}
        doseUnit={props.units || ""}
      />
    </>
  );
};
