import { IconButton, Modal, Typography, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Close as CloseIcon } from "@mui/icons-material";

interface Props {
  url: string;
  handleClose: () => void;
  isOpen: boolean;
  fileName: string;
}

const ImageModal = (props: Props): JSX.Element => {
  const theme = useTheme();

  return (
    <Modal
      open={props.isOpen}
      onClose={props.handleClose}
      data-testid="expanded-image-modal"
    >
      <Stack padding={2} height={1} bgcolor={theme.palette.grey[900]}>
        <Stack
          direction="row"
          color="white"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>{props.fileName}</Typography>
          <IconButton onClick={props.handleClose} color="inherit">
            <CloseIcon />
          </IconButton>
        </Stack>
        <img src={props.url} alt="Expanded Image" />
      </Stack>
    </Modal>
  );
};

export default ImageModal;
